import { Button, Form, Input, InputNumber, Upload } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import * as palette from "../../styles/variables";
import { updateComplexity } from "../../utils/services/complexity";
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
export const EditComplexity = ({ data, cancel, updatedData }) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const [fileList, setFileList] = useState([]);

  const get_url_extension = (url) => {
    return url.split(/[#?]/)[0].split('.').pop().trim();
  }

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        complexity_name: data.complexity_name,
        examples: data.examples,
        response: data.response,
        assemblies: data.assemblies,
        sub_assemblies: data.sub_assemblies,
        no_parts: data.no_parts,
        id: data.id
      });
      setFileList([...fileList, {
        uid: '1',
        name: data?.s3_url.split('/').pop().split('#')[0].split('?')[0],
        status: 'done',
        url: data?.s3_url,
        percent: 0,
      }]);
    }
  }, [data]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    defaultFileList: [
      {
        uid: '1',
        name: data?.s3_url.split('/').pop().split('#')[0].split('?')[0],
        status: 'done',
        url: data?.s3_url,
        percent: 0,
      }
    ],
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };


  const onEditComplexity = (values) => {
    const formData = new FormData();
    formData.append('assemblies', values.assemblies);
    formData.append('complexity_name', values.complexity_name);
    formData.append('examples', values.examples);
    formData.append('no_parts', values.no_parts);
    formData.append('response', values.response);
    formData.append('sub_assemblies', values.sub_assemblies);
    formData.append('id', data.id);
    if (fileList?.length > 0 && !fileList[0].url) {
      formData.append('file', fileList[0]);
    }
    setLoading(true);
    updateComplexity(
      formData,
      (res) => {
        setLoading(false);
        updatedData(res.data);
        cancel();
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  return (
    <FormContainer>
      <Form
        form={form}
        name="Add assignee"
        colon={false}
        requiredMark={false}
        labelCol={{ span: 24 }}
        onFinish={onEditComplexity}
        autoComplete="off"
        initialValues={{
          file: [
            {
              uid: '1',
              name: data?.s3_url.split('/').pop().split('#')[0].split('?')[0],
              status: 'done',
              url: data?.s3_url,
              percent: 0,
            }
          ]
        }}
      >
        <div className="formItems">
          <Form.Item
            label="Complexity"
            name="complexity_name"
            rules={[
              {
                required: true,
                message: "Please enter the complexity",
              },
            ]}
          >
            <Input
              placeholder="Enter the complexity"
              style={{
                width: "98%",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Examples"
            name="examples"
            rules={[
              {
                required: true,
                message: "Please enter the examples",
              },
            ]}
          >
            <Input
              placeholder="Enter the examples"
              style={{
                width: "98%",
              }}
            />
          </Form.Item>

          <Form.Item
            label="Response"
            name="response"
            rules={[
              {
                required: false,
                message: "Please enter the response",
              },
            ]}
          >
            <Input
              placeholder="Enter the response"
              style={{
                width: "98%",
              }}
            />
          </Form.Item>

          <Form.Item
            label="Assemblies"
            name="assemblies"
            rules={[
              {
                required: true,
                message: "Please enter the assemblies.",
              },
            ]}
          >
            <InputNumber
              placeholder="Enter the assemblies"
              style={{
                width: "98%",
              }}
            />
          </Form.Item>

          <Form.Item
            label="Sub Assemblies"
            name="sub_assemblies"
            rules={[
              {
                required: true,
                message: "Please enter the sub assemblies.",
              },
            ]}
          >
            <InputNumber
              placeholder="Enter the sub assemblies"
              style={{
                width: "98%",
              }}
            />
          </Form.Item>

          <Form.Item
            label="No. Parts"
            name="no_parts"
            rules={[
              {
                required: true,
                message: "Please enter the parts.",
              },
            ]}
          >
            <InputNumber
              placeholder="Enter the parts"
              style={{
                width: "98%",
              }}
            />
          </Form.Item>

          <Form.Item label="Upload" name="file" valuePropName="fileList" multiple={false} getValueFromEvent={normFile} rules={[
            {
              required: true,
              message: "Please upload the image",
            },
          ]}>
            <Upload {...props} listType="picture" multiple={false} maxCount={1} accept="image/*" >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>

        </div>
        <div className="footerBtn">
          <Form.Item>
            <Button className="outlinedBtn" onClick={cancel}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              className="primaryBtn"
              htmlType="submit"
              style={{ padding: "0px 24px" }}
              disabled={loading}
            >
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  padding: 20px 0px;
  .formItems {
    display: grid;
    grid-template-columns: auto auto auto;
    @media only screen and (max-width: 769px) {
      grid-template-columns: auto auto;
    }
  }
  .footerBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 20px 30px 0px;
    gap: 20px;
    .ant-form-item {
      margin-bottom: 0px;
    }
  }
`;
