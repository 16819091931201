import { Table, Tooltip } from "antd";
import { NavBar } from "../../components/navigation";
import styled from "styled-components";
import * as palette from "../../styles/variables";
import EditImg from "../../images/edit.svg";
import PlusImg from "../../images/plus.svg";
import DeleteImg from "../../images/delete.svg";
import { useEffect, useState } from "react";
import {
  getAllBomBuild,
  getAllEstimateLevel,
  deleteBOM,
  deleteEstimateLevel,
} from "../../utils/services/multiplier";
import { Loader } from "../../components/Loader";
import swal from "sweetalert";
import { Modal } from "antd";
import { EditBomMultiplier } from "./EditBomMultiplier";
import { AddBomMultiplier } from "./AddBomMultiplier";
import { EditEstimateLevelMultiplier } from "./EditEstimateLevelMultiplier";
import { AddEstimateLevelMultiplier } from "./AddEstimateLevelMultiplier";

// complexity
export const Multiplier = () => {
  const [loading, setLoading] = useState(true);

  const [bomBuildList, setBomVuildList] = useState([]);
  const [estimateLevelList, setEstimateLevelList] = useState([]);
  const [isEditEstimateLevelModalOpen, setEditEstimateLevelModalOpen] = useState(false);
  const [isAddBomModalOpen, setAddBomIsModalOpen] = useState(false);
  const [isAddEstimateLevelModalOpen, setAddEstimateLevelIsModalOpen] = useState(false);
  const [dataItem, setDataItem] = useState(null)

  // Edit bom build muliplier
  const [isEditBomModalOpen, setEditBomIsModalOpen] = useState(false);
  const [dataItemComp, setDataItemComp] = useState(null)

  const showModal = (data) => {
    setDataItemComp(data)
    setEditBomIsModalOpen(true);
  };
  const handleOk = () => {
    setEditBomIsModalOpen(false);
  };
  const handleCancel = () => {
    setEditBomIsModalOpen(false);
  };

  const updateData = (values) => {
    swal("BOM Build has been updated!", {
      icon: "success",
    });
    const bomBuildListCopy = bomBuildList.map((items) =>
      items.id === values.id ? { ...bomBuildList, ...values } : items
    );
    setBomVuildList(bomBuildListCopy);
  };

  const updatedAddData = (values) => {
    swal("BOM Build has been added!", {
      icon: "success",
    });
    // const bomBuildListCopy = bomBuildList.push(values);
    // setBomVuildList(bomBuildListCopy);
    setBomVuildList([values, ...bomBuildList]);
  };
  // Add Bom multipliers

  const showAddModal = (data) => {
    setAddBomIsModalOpen(data);
  };
  const addhandleOk = () => {
    setAddBomIsModalOpen(false);
  };
  const addhandleCancel = () => {
    setAddBomIsModalOpen(false);
  };

  // Edit estimate level muliplier
  const showEstimateLevelModal = (data) => {
    setDataItem(data)
    setEditEstimateLevelModalOpen(true);
  };
  const editEstimateLevelhandleOk = () => {
    setEditEstimateLevelModalOpen(false);
  };
  const editEstimateLevelhandleCancel = () => {
    setEditEstimateLevelModalOpen(false);
  };

  const updateDataEstimateLevel = (values) => {
    const estimateLevelListCopy = estimateLevelList.map((items) =>
      items.id === values.id ? { ...estimateLevelList, ...values } : items
    );
    swal("Estimate Level has been updated!", {
      icon: "success",
    });
    setEstimateLevelList(estimateLevelListCopy);
  };

  // Add estimate level multipliers
  const addEstimateLevelModal = () => {
    setAddEstimateLevelIsModalOpen(true);
  };
  const addEstimateLevelhandleOk = () => {
    setAddEstimateLevelIsModalOpen(false);
  };
  const addEstimateLevelhandleCancel = () => {
    setAddEstimateLevelIsModalOpen(false);
  };

  const addUpdateDataEstimateLevel = (values) => {
    swal("Estimate Level has been created!", {
      icon: "success",
    });
    // const estimateLevel = estimateLevelList.push(values);
    // setAddEstimateLevelIsModalOpen(estimateLevel);
    setEstimateLevelList([values, ...estimateLevelList]);
  };

  // BOM Build
  useEffect(() => {
    getAllBomBuild(
      (res) => {
        setBomVuildList(res.data);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  }, []);

  // Estimate Levels
  useEffect(() => {
    getAllEstimateLevel(
      (res) => {
        setEstimateLevelList(res.data);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  }, []);

  const handleBOMEdit = (record) => {
    var data = {
      id: record.id,
      items: record.items,
      multiplier: record.multiplier,
    };
    showModal(data);
  };

  const handleEstimateLevelEdit = (record) => {
    var data = {
      id: record.id,
      name: record.name,
      multiplier: record.multiplier,
      estimate_level: record.estimate_level,
      response: record.response,
    };
    showEstimateLevelModal(data);
  };
  // BOM Delete
  const handleBOMDeleteClick = (record) => {
    swal({
      title: record.items,
      text: "Are you sure you want to delete this BOM?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((willDelete) => {
      if (willDelete) {
        var data = {
          id: record.id,
        };
        deleteBOM(
          data,
          (success) => {
            const updatedList = bomBuildList.filter(
              (item) => item.id !== record.id
            );
            setBomVuildList(updatedList);
          },
          (errorMessages) => { }
        );
        swal("BOM Build has been deleted!", {
          icon: "success",
        });
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
  };

  // Estimate Level Delete

  const handleEstimateDeleteClick = (record) => {
    swal({
      text: "Are you sure you want to delete this estimate level?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((willDelete) => {
      if (willDelete) {
        var data = {
          id: record.id,
        };
        deleteEstimateLevel(
          data,
          (success) => {
            const updatedList = estimateLevelList.filter(
              (item) => item.id !== record.id
            );
            setEstimateLevelList(updatedList);
          },
          (errorMessages) => { }
        );
        swal("Estimate Level has been deleted!", {
          icon: "success",
        });
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
  };

  // BOM Build coulmns
  const columns = [
    {
      title: "S.NO",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      width: "5%",
      render: (index, text, record) => (
        record + 1
      ),
    },

    {
      title: "Product Complexity",
      dataIndex: "items",
      key: "items",
      ellipsis: true,
      width: "40%",
    },
    {
      title: "Multipler",
      dataIndex: "multiplier",
      key: "multiplier",
      ellipsis: true,
      width: "10%",
    },

    {
      title: "Edit",
      key: "view",
      align: "center",
      render: (_, record) => (
        <Tooltip
          placement="top"
          title="Edit"
          onClick={() => handleBOMEdit(record)}
        >
          <ActionItem style={{ margin: "0px 12px" }}>
            <img src={EditImg} style={{ width: "17px" }} alt="edit" />
          </ActionItem>
        </Tooltip>
      ),
      width: "10%",
    },
    // {
    //   title: "Delete",
    //   key: "delete",
    //   align: "center",
    //   render: (_, record) => (
    //     <Tooltip
    //       placement="top"
    //       title="Delete"
    //       onClick={() => handleBOMDeleteClick(record)}
    //     >
    //       <ActionItem style={{ margin: "0px 15px" }}>
    //         <img src={DeleteImg} style={{ width: "17px" }} alt="delete" />
    //       </ActionItem>
    //     </Tooltip>
    //   ),
    //   width: "10%",
    // },
  ];

  // Estimate Level columns
  const columns2 = [
    {
      title: "S.NO",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      width: "5%",
      render: (index, text, record) => (
        record + 1
      ),
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: "40%",
    },
    {
      title: "Estimated Level",
      dataIndex: "estimate_level",
      key: "estimate_level",
      ellipsis: true,
      width: "20%",
    },
    {
      title: "Multipler",
      dataIndex: "multiplier",
      key: "multiplier",
      ellipsis: true,
      width: "20%",
    },

    {
      title: "Edit",
      key: "view",
      align: "center",
      render: (_, record) => (
        <Tooltip
          placement="top"
          title="Edit"
          onClick={() => handleEstimateLevelEdit(record)}
        >
          <ActionItem style={{ margin: "0px 12px" }}>
            <img src={EditImg} style={{ width: "17px" }} alt="edit" />
          </ActionItem>
        </Tooltip>
      ),
    },
    // {
    //   title: "Delete",
    //   key: "delete",
    //   align: "center",
    //   render: (_, record) => (
    //     <Tooltip
    //       placement="top"
    //       title="Delete"
    //       onClick={() => handleEstimateDeleteClick(record)}
    //     >
    //       <ActionItem style={{ margin: "0px 15px" }}>
    //         <img src={DeleteImg} style={{ width: "17px" }} alt="delete" />
    //       </ActionItem>
    //     </Tooltip>
    //   ),
    // },
  ];

  return (
    <>
      <NavBar />
      <div>
        <p style={{ "margin-left": "4vh", "font-weight": "600 !important" }}>
          BOM Build Multiplier
          {/* <span
            style={{ float: "right", "margin-right": "6vh", cursor: "pointer" }}
            onClick={showAddModal}
          >
            <img src={PlusImg} alt=""></img>
          </span>{" "} */}
        </p>
      </div>
      <TableWrapper>
        {loading ? (
          <Loader />
        ) : (
          <Table
            columns={columns}
            dataSource={bomBuildList}
            scroll={{ x: true, y: true }}
            pagination={{
              position: ["bottomRight"],
              pageSize: 10,
            }}
          />
        )}
      </TableWrapper>

      <p style={{ "margin-left": "4vh", "font-weight": 600 }}>
        Estimate Level Multiplier
        {/* <span
          style={{ float: "right", "margin-right": "6vh", cursor: "pointer" }}
          onClick={addEstimateLevelModal}
        >
          <img src={PlusImg} alt=""></img>
        </span>{" "} */}
      </p>
      <TableWrapper>
        {loading ? (
          <Loader />
        ) : (
          <Table
            columns={columns2}
            dataSource={estimateLevelList}
            scroll={{ x: true, y: true }}
            pagination={{
              position: ["bottomRight"],
              pageSize: 10,
            }}
          />
        )}
      </TableWrapper>

      <Modal
        open={isEditBomModalOpen}
        title="Edit BOM Build"
        centered={true}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{
          top: 20,
        }}
        width={630}
        destroyOnClose={true}
        className="editModal"
      >
        <EditBomMultiplier
          data={dataItemComp}
          cancel={handleCancel}
          updatedData={updateData}
        />
      </Modal>

      <Modal
        open={isAddBomModalOpen}
        title="Add BOM Build"
        centered={true}
        footer={null}
        onOk={addhandleOk}
        onCancel={addhandleCancel}
        style={{
          top: 0,
        }}
        width={630}
        destroyOnClose={true}
        className="editModal"
      >
        <AddBomMultiplier
          cancel={addhandleCancel}
          updatedData={updatedAddData}
        />
      </Modal>

      <Modal
        open={isEditEstimateLevelModalOpen}
        title="Edit Estimate Level"
        centered={true}
        footer={null}
        onOk={editEstimateLevelhandleOk}
        onCancel={editEstimateLevelhandleCancel}
        style={{
          top: 20,
        }}
        width={900}
        destroyOnClose={true}
        className="editModal"
      >
        <EditEstimateLevelMultiplier
          data={dataItem}
          cancel={editEstimateLevelhandleCancel}
          updatedData={updateDataEstimateLevel}
        />
      </Modal>

      <Modal
        open={isAddEstimateLevelModalOpen}
        title="Add Estimate Level"
        centered={true}
        footer={null}
        onOk={addEstimateLevelhandleOk}
        onCancel={addEstimateLevelhandleCancel}
        style={{
          top: 0,
        }}
        width={900}
        destroyOnClose={true}
        className="editModal"
      >
        <AddEstimateLevelMultiplier
          data={isAddEstimateLevelModalOpen}
          cancel={addEstimateLevelhandleCancel}
          updatedData={addUpdateDataEstimateLevel}
        />
      </Modal>
    </>
  );
};

const TableWrapper = styled.div`
  padding: 25px;
  .ant-table {
    border-radius: ;
  }
  .ant-table-thead .ant-table-cell {
    background: transparent;
    border-bottom: 1px solid ${palette.WHITE_SMOKE};
    text-transform: uppercase;
    font-family: ${palette.FONT_FAMILY};
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: ${palette.PRIMARY_COLOR};
    padding: 12px 7px !important;
    &:before {
      display: none;
    }
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    border-bottom: none;
    padding: 12px 7px !important;
    font-family: ${palette.FONT_FAMILY};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: ${palette.PRIMARY_COLOR};
  }
`;

const ActionItem = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
