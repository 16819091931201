import { BASE_URL, QUOTULATOR_URL } from '../helper';
import logger from '../logger';
import Interceptor from './interceptor';

const request = new Interceptor();

export const getAllEstimations = async (success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.get(`${BASE_URL}/${QUOTULATOR_URL}`, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};

export const getEstimationView = async (id, success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.get(`${BASE_URL}/${QUOTULATOR_URL}/${id}/view`, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};

export const deleteEstimation = async (data, success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.delete(`${BASE_URL}/${QUOTULATOR_URL}/${data.id}`, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};

export const getEstimateLevel = async (success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.get(`${BASE_URL}/settings/estimateLevel`, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};

export const getComplexity = async (success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.get(`${BASE_URL}/settings/complexity`, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};

export const getEstimateData = async (success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.get(`${BASE_URL}/settings/availableData`, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};

export const updateEstimation = async (data, success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.put(`${BASE_URL}/${QUOTULATOR_URL}/`, data, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};
