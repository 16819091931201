import { Button, Form, Input, InputNumber, Select } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import * as palette from "../../styles/variables";
import { addUser } from "../../utils/services/assignee";

export const AddAssignee = ({ cancel, updatedData }) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (data) {
  //     form.setFieldsValue({
  //       name: data.name,
  //       email: data.email,
  //       password: data.password,
  //       mobile: data.mobile,
  //     });
  //   }
  // }, [data]);

  const onAddUser = (values) => {
    setLoading(true);
    addUser(
      values,
      (res) => {
        setLoading(false);
        updatedData(res.data);
        cancel();
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  return (
    <FormContainer>
      <Form
        form={form}
        name="Add assignee"
        colon={false}
        requiredMark={false}
        labelCol={{ span: 24 }}
        onFinish={onAddUser}
        autoComplete="off"
      >
        <div className="formItems">
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter the name",
              },
            ]}
          >
            <Input
              placeholder="Enter the name"
              style={{
                width: "98%",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: "Please enter the email",
              },
            ]}
          >
            <Input
              placeholder="Enter the email"
              style={{
                width: "98%",
              }}
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter the password",
              },
            ]}
          >
            <Input.Password 
              placeholder="Enter the password"
              style={{
                width: "98%",
              }}
            />
          </Form.Item>

          <Form.Item
            label="Mobile No"
            name="mobile"
            rules={[
              {
                required: true,
                message: "Please enter the mobile no.",
              },
            ]}
          >
            <InputNumber
              placeholder="Enter the mobile number"
              style={{
                width: "98%",
              }}
            />
          </Form.Item>

          <Form.Item
            label="Role"
            name="role"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              defaultValue=""
              style={{
                width: 120,
              }}
              // onChange={handleChange}
              options={[
                {
                  value: 'Admin',
                  label: 'Admin',
                },
                {
                  value: 'Assignee',
                  label: 'Assignee',
                },
              ]}
            />
          </Form.Item>
        </div>
        <div className="footerBtn">
          <Form.Item>
            <Button className="outlinedBtn" onClick={cancel}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              className="primaryBtn"
              htmlType="submit"
              style={{ padding: "0px 24px" }}
              disabled={loading}
            >
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  padding: 20px 0px;
  .formItems {
    display: grid;
    grid-template-columns: auto auto auto;
    @media only screen and (max-width: 769px) {
      grid-template-columns: auto auto;
    }
  }
  .footerBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 20px 30px 0px;
    gap: 20px;
    .ant-form-item {
      margin-bottom: 0px;
    }
  }
`;
