import { Table, Tooltip } from "antd";
import { NavBar } from "../../components/navigation";
import styled from "styled-components"
import * as palette from '../../styles/variables';
import moment from "moment";
import ViewImg from "../../images/view.svg";
import DeleteImg from "../../images/delete.svg";
import { useEffect, useState } from "react";
import { getAllEstimations , deleteEstimation} from "../../utils/services/quotulator";
import { Loader } from "../../components/Loader";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

export const HomePage = () => {

  const navigate = useNavigate();

  const[loading, setLoading] = useState(true);

  const [estimationsList, setEstimationsList] = useState([]);

  useEffect(() => {
    getAllEstimations(
      res => {
        setEstimationsList(res.data)
        setLoading(false);
      },
      err => {
        setLoading(false);
      }
    );
  }, [])

  const handleDeleteClick = (record) => {
    swal({
      // title: record.items,
      text:
        "Are you sure you want to delete this estimation?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((willDelete) => {
      if (willDelete) {
        var data = {
          id: record.id
        };
        deleteEstimation(
          data,
          success => {
            const updatedList = estimationsList.filter(item => item.id !== record.id);
            setEstimationsList(updatedList)
          },
          errorMessages => {}
        );
        swal("Estimation has been deleted!", {
          icon: "success",
        });
      } else {
        // swal("Your imaginary file is safe!");
      }
    })
  }
  const columns = [
    {
      title: 'Enquiry Number',
      dataIndex: 'id',
      key: 'id',
      // ellipsis: true,
      // render: (index,text, record) => (
      //   record + 1
      // ),
       render: (index, text, record) => {
         return <div style={{width: "60px", wordBreak: 'break-word'}}>{text.id}</div>
       },
       width: "12%"
    },
    {
      title: 'Customer',
      dataIndex: 'customer_name',
      key: 'customer_name',
      ellipsis: true,
      width: "10%"
    },
    {
      title: 'Mail Id',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      width: "13%"
    },
    {
      title: 'Product Number',
      dataIndex: 'part_number',
      key: 'part_number',
      width: "12%",
      render: (text, record) => {
        return <div style={{width: "80px", wordBreak: 'break-word'}}>{text}</div>
      },
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (text, record) => (
        <div style={{ width: "140px", wordWrap: 'break-word', wordBreak: 'break-word' }}>
          {text}
        </div>
      ),
      width: "15%"
    },
    {
      title: 'Date',
      dataIndex: 'created_date',
      key: 'created_date',
      ellipsis: true,
      render: (text) => <span>{moment(text).format('DD MMM')}</span>,
      width: "6%"
    },
    {
      title: 'Price',
      dataIndex: 'package_cost',
      key: 'package_cost',
      ellipsis: true,
      render: (text) => <span>€{text}</span>,
      width: "6%"
    },
    {
      title: 'Assignee',
      dataIndex: 'assignee',
      key: 'assignee',
      ellipsis: true,
      width: "8%"
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      ellipsis: true,
      width: "4%",
      render: (_, { status }) => (
        status && <TagStatus status={status}>
          {status}
        </TagStatus>
      ),
    },
    {
      title: 'View',
      key: 'view',
      align: "center",
      render: (_, record) => (
        <Tooltip placement="top" title="View">
          <ActionItem style={{ margin: "0px 12px"}} onClick={() => navigate(`/${record.id}`)}><img src={ViewImg} style={{width: "20px"}} alt="view"/></ActionItem>
        </Tooltip>
      ),
    },
    {
      title: 'Delete',
      key: 'delete',
      align: "center",
      render: (_, record) => (
        <Tooltip placement="top" title="Delete"  onClick={()=>handleDeleteClick(record)}>
          <ActionItem style={{ margin: "0px 15px"}}><img src={DeleteImg} style={{width: "17px"}} alt="delete"/></ActionItem>
        </Tooltip>
      ),
    },
  ];

  return(
    <>
      <NavBar />
      <TableWrapper>
        {
          loading ? <Loader /> :
          <Table
            columns={columns}
            dataSource={estimationsList}
            scroll={{ x: true, y: true }}
            pagination={{
              position: ["topRight"],
              pageSize: 50
            }}
          />
        }
      </TableWrapper>
    </>
  )
};

const TableWrapper = styled.div`
  padding: 25px;
  .ant-table {
  }
  .ant-table-thead .ant-table-cell {
    background: transparent;
    border-bottom: 1px solid ${palette.WHITE_SMOKE};
    text-transform: uppercase;
    font-family: ${palette.FONT_FAMILY};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: ${palette.PRIMARY_COLOR};
    padding: 12px 7px !important;
    &:before {
      display: none;
    }
  }
  .ant-table-wrapper .ant-table-tbody >tr >td {
    border-bottom: none;
    padding: 12px 7px !important;
    font-family: ${palette.FONT_FAMILY};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: ${palette.PRIMARY_COLOR};
  }
`;

const TagStatus = styled.div`
  padding: 5px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 10px;
  text-transform: capitalize;
  text-align: center;
  background: ${({status}) => status === "reviewed" ? "#FFEBEB" : status === "submitted" ? "#F7F3E4" : "#E4FAEE"};
  color: ${({status}) => status === "reviewed" ? "#AB1717" : status === "submitted" ? "#B9A855" : "#489775"};
`;

const ActionItem = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
