import { BASE_URL, CUSTOMERS_URL } from '../helper';
import logger from '../logger';
import Interceptor from './interceptor';

const request = new Interceptor();

export const getAllCustomer = async (success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.get(`${BASE_URL}/${CUSTOMERS_URL}`, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};
