import { Modal } from "antd";
import { NavBar } from "../../components/navigation";
import EditImg from "../../images/edit.svg";
import { useEffect, useState } from "react";
import { getAll } from "../../utils/services/estimated-time";
import { Loader } from "../../components/Loader";
import { EditEstimatedTime } from "./EditEstimatedTime";
import swal from "sweetalert";

// complexity
export const EstimatedTime = () => {
  const [loading, setLoading] = useState(true);

  const [estimatedTimeList, setEstimatedTimeList] = useState([]);

  //edit estimate level muliplier
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getAll(
      (res) => {
        setEstimatedTimeList(res.data);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  }, []);

  const updateData = (values) => {
    swal("Estimated time has been updated!", {
      icon: "success",
    });
    setEstimatedTimeList([values])
  }

  return (
    <>
      <NavBar />
      <div>
        {loading ? (
          <Loader />
        ) : (
          <table
            style={{ width: 500, "text-align": "left", "margin-left": "10vh" }}
          >
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th style={{ cursor: "pointer", padding: "10px" }} onClick={showModal}>
                  <img src={EditImg} style={{ width: "20px" }} alt="" />
                </th>
              </tr>
              <tr>
                <th style={{ padding: "10px" }}>S.No</th>
                <th style={{ padding: "10px" }}>Task</th>
                <th style={{ padding: "10px" }}>Values</th>
              </tr>
            </thead>

            <tbody style={{ width: 500 }}>
              <tr>
                <td style={{ padding: "10px" }}>1</td>
                <td style={{ padding: "10px" }}>Set up Job (Mins)</td>
                <td style={{ padding: "10px" }}>{estimatedTimeList[0].setup_job}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>2</td>
                <td style={{ padding: "10px" }}>Job Evalution (Mins/Estimate)</td>
                <td style={{ padding: "10px" }}>{estimatedTimeList[0].job_evalution}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>3</td>
                <td style={{ padding: "10px" }}>Build a BOM - Base (Mins)</td>
                <td style={{ padding: "10px" }}>{estimatedTimeList[0].build_bom_base}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>4</td>
                <td style={{ padding: "10px" }}>Build a BOM Per Estimate (Mins/Estimate)</td>
                <td style={{ padding: "10px" }}>{estimatedTimeList[0].build_bom_estimate}</td>
              </tr>
              {/* <tr>
                <td style={{padding: "10px"}}>5</td>
                <td style={{padding: "10px"}}>Data Available</td>
                <td style={{padding: "10px"}}>{estimatedTimeList[0].data_available}</td>
              </tr> */}

              <tr>
                <td style={{ padding: "10px" }}>5</td>
                <td style={{ padding: "10px" }}>Report To Client - Base (Mins)</td>
                <td style={{ padding: "10px" }}>{estimatedTimeList[0].report_to_client_base}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>6</td>
                <td style={{ padding: "10px" }}>Report To Client - Per Estimate (Mins/Estimate)</td>
                <td style={{ padding: "10px" }}>{estimatedTimeList[0].report_to_client_estimate}</td>
              </tr>

              <tr>
                <td style={{ padding: "10px" }}>7</td>
                <td style={{ padding: "10px" }}>Make Estimate - Per Process (Mins/Estimate)</td>
                <td style={{ padding: "10px" }}>{estimatedTimeList[0].make_estimate}</td>
              </tr>

              <tr>
                <td style={{ padding: "10px" }}>8</td>
                <td style={{ padding: "10px" }}>Quality Assurance - Base (Mins)</td>
                <td style={{ padding: "10px" }}>{estimatedTimeList[0].quality_assurance_base}</td>
              </tr>

              <tr>
                <td style={{ padding: "10px" }}>9</td>
                <td style={{ padding: "10px" }}>Quality Assurance - Per Estimate (Mins/Estimate)</td>
                <td style={{ padding: "10px" }}>{estimatedTimeList[0].quality_assurance_estimate}</td>
              </tr>


              <tr>
                <td style={{ padding: "10px" }}>10</td>
                <td style={{ padding: "10px" }}>Contingency(%)</td>
                <td style={{ padding: "10px" }}>{estimatedTimeList[0].contingency}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>11</td>
                <td style={{ padding: "10px" }}>Efficiency Allowance(%)</td>
                <td style={{ padding: "10px" }}>{estimatedTimeList[0].efficiency}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>12</td>
                <td style={{ padding: "10px" }}>Hourly Rate (€/hr)</td>
                <td style={{ padding: "10px" }}>{estimatedTimeList[0].rate}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>13</td>
                <td style={{ padding: "10px" }}>Typical no of manufacturing processes per part (Nos) </td>
                <td style={{ padding: "10px" }}>{estimatedTimeList[0].process_component}</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      <Modal
        open={isModalOpen}
        title="Edit Estimate Time"
        centered={true}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{
          top: 20,
        }}
        width={900}
        destroyOnClose={true}
        className="editModal"
      >
        <EditEstimatedTime data={estimatedTimeList[0]} cancel={handleCancel} updatedData={updateData} />
      </Modal>
    </>
  );
};
