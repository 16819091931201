import { BASE_URL, AVAILABLE_DATA_URL } from '../helper';
import logger from '../logger';
import Interceptor from './interceptor';

const request = new Interceptor();

export const getData = async (success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.get(`${BASE_URL}/${AVAILABLE_DATA_URL}`, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};


export const deleteData = async (data, success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.delete(`${BASE_URL}/${AVAILABLE_DATA_URL}/${data.id}`, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};


export const addData = async (data, success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.post(`${BASE_URL}/${AVAILABLE_DATA_URL}`, data, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};

export const updateData = async (data, success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.put(`${BASE_URL}/${AVAILABLE_DATA_URL}`, data, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};