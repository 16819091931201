import styled from "styled-components"
import * as palette from '../styles/variables';

export const ProfilePic = ({src}) => {
  return(
    <ProfileWrapper>
      <img src={src} alt="profile" />
    </ProfileWrapper>
  )
}

const ProfileWrapper = styled.div`
  background: ${palette.APP_BACKGROUND_COLOR};
  width: 32px;
  height: 32px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 32px;
    height: 32px;
  }
`;
