import { BASE_URL, SETTING_URL } from '../helper';
import logger from '../logger';
import Interceptor from './interceptor';

const request = new Interceptor();

export const getAll = async (success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.get(`${BASE_URL}/${SETTING_URL}`, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};

export const updateEstimtedTime = async (data, success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.put(`${BASE_URL}/${SETTING_URL}`, data, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};
