import { Table, Tooltip } from "antd";
import { NavBar } from "../../components/navigation";
import styled from "styled-components";
import * as palette from "../../styles/variables";
import moment from "moment";
import EditImg from "../../images/edit.svg";
import PlusImg from "../../images/plus.svg";
import DeleteImg from "../../images/delete.svg";
import { useEffect, useState } from "react";
import { getAllCustomer } from "../../utils/services/customers";
import { Loader } from "../../components/Loader";

// complexity
export const Customers = () => {
  const [loading, setLoading] = useState(true);

  const [customersList, setCustomersList] = useState([]);

  useEffect(() => {
    getAllCustomer(
      (res) => {
        setCustomersList(res.data.data);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  }, []);

  const columns = [
    {
      title: "S.NO",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      render: (index,text, record) => (
        record + 1
     ),
     width:"5%"
    },

    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Email Id",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
    },
    {
      title: "Mobile No.",
      dataIndex: "mobile",
      key: "mobile",
      ellipsis: true,
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      ellipsis: true,
      //   render: (text) => <span>{moment(text).format('DD MMM')}</span>
    },
    {
      title: "Reg Date",
      dataIndex: "created_date",
      key: "created_date",
      ellipsis: true,
      render: (text) => <span>{moment(text).format("DD MMM Y")}</span>,
    },

    // {
    //   title: "Edit",
    //   key: "view",
    //   align: "center",
    //   render: (_, record) => (
    //     <Tooltip placement="top" title="Edit">
    //       <ActionItem style={{ margin: "0px 12px" }}>
    //         <img src={EditImg} style={{ width: "17px" }} alt="edit" />
    //       </ActionItem>
    //     </Tooltip>
    //   ),
    // },
    // {
    //   title: "Delete",
    //   key: "delete",
    //   align: "center",
    //   render: (_, record) => (
    //     <Tooltip placement="top" title="Delete">
    //       <ActionItem style={{ margin: "0px 15px" }}>
    //         <img src={DeleteImg} style={{ width: "17px" }} alt="delete" />
    //       </ActionItem>
    //     </Tooltip>
    //   ),
    // },
  ];

  return (
    <>
      <NavBar />

      <TableWrapper>
        {loading ? (
          <Loader />
        ) : (
          <Table
            columns={columns}
            dataSource={customersList}
            scroll={{ x: true, y: true }}
            pagination={{
              position: ["bottomRight"],
              pageSize: 10,
            }}
          />
        )}
      </TableWrapper>
    </>
  );
};

const TableWrapper = styled.div`
  padding: 25px;
  .ant-table {
    border-radius: ;
  }
  .ant-table-thead .ant-table-cell {
    background: transparent;
    border-bottom: 1px solid ${palette.WHITE_SMOKE};
    text-transform: uppercase;
    font-family: ${palette.FONT_FAMILY};
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: ${palette.PRIMARY_COLOR};
    padding: 12px 7px !important;
    &:before {
      display: none;
    }
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    border-bottom: none;
    padding: 12px 7px !important;
    font-family: ${palette.FONT_FAMILY};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: ${palette.PRIMARY_COLOR};
  }
`;
