import { Route, Routes, useLocation } from 'react-router-dom';
import { HomePage } from './containers/Homepage';
import { Complexity } from './containers/Complexity';
import styled from "styled-components"
import { SideBar } from './components/navigation';
import { EstimatedTime } from './containers/Estimated-Time';
import { Customers } from './containers/Customers';
import { Assignee } from './containers/Assignee';
import { AvailableData } from "./containers/AvailableData"
import { Multiplier } from './containers/Multiplier';
import { LoginPage } from './containers/LoginPage';
import { EstimationPage } from './containers/EstimationPage';
import { ProtectedRouteLayout } from './navigation/ProtectedRouteLayout';
import { UnprotectedRouteLayout } from './navigation/UnProtectedRouteLayout';

const App = () => {

  const { pathname } = useLocation()

  return (
    <>
      {/* {
        sessionStorage.getItem('loggedin') ? */}
        <AppWrapper>
          {!pathname.includes("/login") && <SideBar />}
          <div
            style={{
              width: "100%",
              overflow: "auto",
              overflowY: "auto",
              position: "relative"
            }}
          >
            <Routes>
              <Route element={<ProtectedRouteLayout />}>
                <Route element={<HomePage />} path="/" />
                <Route element={<EstimationPage />} path="/:id" />
                <Route element={<Complexity />} path="/complexity" />
                <Route element={<EstimatedTime />} path="/estimated-time" />
                <Route element={<Customers />} path="/customers" />
                <Route element={<AvailableData />} path="/available-data" />

                
                {
                  sessionStorage.getItem("role") === "Admin" &&
                  <Route element={<Assignee />} path="/assignee" />
                }
                <Route element={<Multiplier />} path="/multiplier" />
              </Route>
              <Route element={<UnprotectedRouteLayout />}>
                <Route element={<LoginPage />} path="/login" />
              </Route>
            </Routes>
          </div>
        </AppWrapper>
      {/* } */}
    </>
  );
}

export default App;

const AppWrapper = styled.div`
  display: flex;
  height: 100%;
`
