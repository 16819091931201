import { createGlobalStyle } from 'styled-components';
// Abstracts
import * as palette from './variables';

export const GlobalStyle = createGlobalStyle`
  @-ms-viewport {
    width: device-width;
  }
  html {
    box-sizing: border-box;
    font-size: 13pt;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
  }

  body {
    -ms-overflow-style: scrollbar;
    line-height: 1;
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    background-color: ${palette.APP_BACKGROUND_COLOR} !important;
    color: ${palette.PRIMARY_COLOR};
  }

  html, body, #root {
    width: 100%;
    height: 100%;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  html, body, div, span, applet, object,
  iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
  pre, a, abbr, acronym, address, big, cite,
  code, del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var, b,
  u, i, center, dl, dt, dd, ol, ul, li, fieldset,
  form, label, legend, table, caption, tbody,
  tfoot, thead, tr, th, td, article, aside,
  canvas, details, embed, figure, figcaption,
  footer, header, hgroup, menu, nav, output, ruby,
  section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after, q:before, q:after {
    content: none;
  }
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  input, select, textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
  }
  body, input, select, textarea, button {
    font-family: ${palette.FONT_FAMILY};
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.65;
  }
  section {
    position: relative;
  }
  a {
    -moz-transition: color 0.2s ease-in-out;
    -webkit-transition: color 0.2s ease-in-out;
    -ms-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
    text-decoration: underline;
  }
  strong {
    font-weight: 600;
  }

  h1,h2,h3,h4,h5,h6 {
    line-height: 1.25;
  }

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 26px;
  }

  h4 {
    font-size: 20px;
  }

  img {
    width: 100%;
  }

  p {
    font-family: ${palette.CONTENT_FONT_FAMILY};
    font-size: 16px;
    line-height: 1.5;
  }

  .ant-pagination .ant-pagination-item-active {
    border-color: ${palette.MAIN_COLOR};
    &:hover, &:focus, &:active {
      border-color: ${palette.MAIN_COLOR};
    }
    a {
      color: ${palette.MAIN_COLOR};
      &:hover, &:focus, &:active {
        color: ${palette.MAIN_COLOR};
      }
    }
  }

  .primaryBtn {
    height: 42px;
    background: ${palette.MAIN_COLOR} !important;
    border-radius: 12px !important;
    color:  ${palette.WHITE_COLOR} !important;
    font-family: ${palette.FONT_FAMILY} !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    &:hover, &:active, &:focus {
      background: ${palette.MAIN_COLOR} !important;
      color:  ${palette.WHITE_COLOR} !important;
    }
  }

  .ant-form-item-label > label {
    font-family: ${palette.FONT_FAMILY};
    font-weight: 500;
    font-size: 14px !important;
    line-height: 18px;
    color: #0E1521;
    height: auto;
  }

  .outlinedBtn {
    height: 42px;
    background: ${palette.WHITE_COLOR} !important;
    border-radius: 12px !important;
    color: ${palette.MAIN_COLOR} !important;
    font-family: ${palette.FONT_FAMILY} !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    border-color: ${palette.MAIN_COLOR} !important;
    &:hover, &:active, &:focus {
      background: ${palette.WHITE_COLOR} !important;
      color:  ${palette.MAIN_COLOR} !important;
    }
  }

  .editModal {
    .ant-modal-content {
      padding: 24px 28px;
    }
    .ant-modal-title {
      font-size: 24px;
      font-family: ${palette.FONT_FAMILY} !important;
      color: ${palette.PRIMARY_COLOR} !important;
    }
    .ant-modal-close {
      width: 24px;
      height: 24px;
    }
    .ant-modal-close-x {
      font-size: 18px;
    }
  }

  .selectInput {
    .ant-select-selector {
      height: 46px !important;
      background: ${palette.WHITE_COLOR} !important;
      border: 1px solid rgba(0, 0, 0, 0.16);
      border-radius: 12px;
      color: ${palette.BLACK_COLOR} !important;
      padding: 7px 16px !important;
      font-family: ${palette.FONT_FAMILY};
      &:focus, &:hover, &:active {
        border: 1px solid rgba(0, 0, 0, 0.16) !important;
      }
    }
  }
  .ant-select-focused:where(.css-dev-only-do-not-override-j0nf2s).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: rgba(0, 0, 0, 0.16) !important;
    color: ${palette.BLACK_COLOR} !important;
  }
  .ant-select-disabled:where(.css-dev-only-do-not-override-1km3mtt).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #FFFFFF !important;
    color: ${palette.BLACK_COLOR} !important;
  }

  .ant-select-item {
    font-family: ${palette.FONT_FAMILY};
  }

`;
