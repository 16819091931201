import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { NavBar } from "../../components/navigation";
import { getComplexity, getEstimateData, getEstimateLevel, getEstimationView, updateEstimation } from "../../utils/services/quotulator";
import BackIcon from "../../images/chevron-left.svg"
import styled from "styled-components";
import moment from "moment";
import { InputLevel } from "./inputLevel";
import { Loader } from "../../components/Loader";
import { Row, Select } from "antd";
import { Col } from "antd";
import { InputNumber } from "antd";
import { Button } from "antd";
import { useImmer } from "use-immer";
import { Input } from "antd";
import { FormWrapper, LabelItem, ListItem, ResultsFormContainer, ResultsList, TotalCostWrapper } from "./styles";
import PaymentImg from '../../images/quotulator/payments.svg'
import { FileIcon, defaultStyles } from 'react-file-icon';
import DownloadIcon from '../../images/download.svg'
import * as palette from '../../styles/variables';
import EditImg from "../../images/edit.svg";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { getUsers } from "../../utils/services/assignee";

export const EstimationPage = () => {

  const { id } = useParams();

  const [estData, setEstData] = useState(null);

  const [estLevelData, setEstLevelData] = useState([]);

  const [complexityData, setComplexityData] = useState([]);

  const [savedValues, setSavedValues] = useImmer({
    level: 0,
    complexity: 0,
    assemblies: 0,
    sub_assemblies: 0,
    parts: 0,
    estimates: 0,
    cost: 0,
    days: 0,
    assemblies_default: 0,
    sub_assemblies_default: 0,
    parts_default: 0,
    estimate_data: 0,
  });

  const [loading, setLoading] = useState(false)

  const [levelLoading, setLevelLoading] = useState(false)

  const [compLoading, setCompLoading] = useState(false)

  const [showEditStatus, setShowEditStatus] = useState(false)

  const [statusValue, setStatusValue] = useState("")

  const [showEditAssignee, setShowEditAssignee] = useState(false)

  const [assigneeValue, setAssigneeValue] = useState("")

  const [assigneeID, setAssigneeID] = useState("")

  const [assigneeList, setAssigneeList] = useState([]);

  const [estimateData, setEstimateData] = useState([]);

  const [estimateLoading, setEstimateLoading] = useState(false)

  useEffect(() => {
    if (id) {
      setLoading(true)
      getEstimationView(
        id,
        async (success) => {
          if (success.data) {
            setEstData(success.data)
            setSavedValues(draft => {
              draft.level = success.data.estimate_level
              draft.complexity = success.data.complexity
              draft.assemblies = success.data.assemblies
              draft.sub_assemblies = success.data.sub_assemblies
              draft.parts = success.data.parts
              draft.estimates = success.data.total_work_package
              draft.cost = success.data.package_cost
              draft.days = success.data.days
              draft.assemblies_default = success.data.assemblies_default
              draft.sub_assemblies_default = success.data.sub_assemblies_default
              draft.parts_default = success.data.parts_default
              draft.estimateData = parseInt(success.data.estimate_data)
            });
            setStatusValue(success.data.status)
            setAssigneeValue(success.data.assignee_name)
            setAssigneeID(success.data.assignee_id)
            setLoading(false)
          }
        },
        (error) => { setLoading(false) }
      )
    }
  }, [id])

  useEffect(() => {
    setLevelLoading(true)
    setCompLoading(true)
    setEstimateLoading(true)
    getEstimateLevel(
      (successdata) => {
        if (successdata.data) {
          setEstLevelData(successdata.data)
        }
        setLevelLoading(false)
      },
      error => {
        setLevelLoading(false)
      }
    )

    getComplexity(
      (successdata) => {
        if (successdata.data) {
          setComplexityData(successdata.data)
        }
        setCompLoading(false)
      },
      error => {
        setCompLoading(false)
      }
    )

    getEstimateData(
      (successdata) => {
        if (successdata.data) {
          setEstimateData(successdata.data)
        }
        setEstimateLoading(false)
      },
      error => {
        setEstimateLoading(false)
      }
    )

    getUsers(
      (res) => {
        let assigneeOptions = [];
        if (res.data.data.length > 0) {
          res.data.data.map(item => {
            var obj = {
              label: item.name,
              value: item.name,
              id: item.id
            };
            assigneeOptions.push(obj)
          })
        }
        setAssigneeList(assigneeOptions);
      },
      (err) => {
      }
    );
  }, [])

  const get_url_extension = (url) => {
    return url.split(/[#?]/)[0].split('.').pop().trim();
  }

  const handleStatusChange = (value) => {
    setStatusValue(value)
  }

  const handleAssigneeChange = (value, option) => {
    setAssigneeID(option.id)
    setAssigneeValue(value)
  }

  const updateField = (param) => {
    var data = {
      id: estData.id,
      status: statusValue,
      assignee_id: assigneeID
    };
    updateEstimation(
      data,
      (success) => {
        setEstData(estData => ({
          ...estData,
          status: statusValue,
          assignee_id: assigneeID,
          assignee_name: assigneeValue
        }));
        setShowEditStatus(false)
        setShowEditAssignee(false)
      },
      (error) => {

      }
    )
  }

  return (
    <>
      <NavBar />
      <Container>
        <Backwrapper>
          <Link to="/">
            <img src={BackIcon} alt="back" />
            Back
          </Link>
        </Backwrapper>
        {
          (loading || levelLoading || compLoading || estimateLoading) ? <Loader /> :
            <div style={{ background: "#FFFFFF", padding: "24px", margin: "20px 0px" }}>
              <InfoListWrapper>
                <li>
                  <label>Customer</label>
                  <span>{estData?.customer_name}</span>
                </li>
                <li>
                  <label>Company Name</label>
                  <span>{estData?.company_name}</span>
                </li>
                <li>
                  <label>Date</label>
                  <span>{moment(estData?.created_date).format("ll")}</span>
                </li>
                <li>
                  <label>Category</label>
                  <span>{estData?.estimate_level_name}</span>
                </li>
                <li>
                  <label>Mail ID</label>
                  <span>{estData?.email}</span>
                </li>
                <li>
                  <label>Price</label>
                  <span>€{estData?.package_cost}</span>
                </li>
                <li>
                  <label>Status</label>
                  <EditWrapper>
                    {
                      showEditStatus ?
                        <Select
                          style={{
                            width: 120,
                          }}
                          value={statusValue}
                          onChange={handleStatusChange}
                          options={[
                            {
                              value: 'enquired',
                              label: 'Enquired',
                            },
                            {
                              value: 'reviewed',
                              label: 'Reviewed',
                            },
                            {
                              value: 'submitted',
                              label: 'Submitted',
                            }
                          ]}
                        />
                        : <StatusText status={estData?.status}>{estData?.status}</StatusText>
                    }
                    {
                      showEditStatus ?
                        <div>
                          <Button icon={<CheckOutlined />} onClick={() => updateField('status')} className="tickIcon"></Button>
                          <Button icon={<CloseOutlined />} onClick={() => { setShowEditStatus(false); setStatusValue(estData.status) }}></Button>
                        </div>
                        : <Button icon={<img src={EditImg} />} onClick={() => setShowEditStatus(true)}></Button>
                    }
                  </EditWrapper>
                </li>
                <li>
                  <label>Assignee</label>
                  <EditWrapper>
                    {
                      showEditAssignee ?
                        <Select
                          style={{
                            width: 120,
                          }}
                          value={assigneeValue}
                          onChange={handleAssigneeChange}
                          options={assigneeList}
                          placeholder="Select assignee"
                        />
                        : <AssigneeDetails>{estData?.assignee_name ? estData?.assignee_name : "Not assigned"}</AssigneeDetails>
                    }
                    {
                      showEditAssignee ?
                        <div>
                          <Button icon={<CheckOutlined />} onClick={() => updateField('assignee')} className="tickIcon"></Button>
                          <Button icon={<CloseOutlined />} onClick={() => { setShowEditAssignee(false); setAssigneeValue(estData.assignee_name) }}></Button>
                        </div>
                        : <Button icon={<img src={EditImg} />} onClick={() => setShowEditAssignee(true)}></Button>
                    }
                  </EditWrapper>
                </li>
                <li>
                  <label>Product Number</label>
                  <span>{estData?.part_number ? estData?.part_number : "-"}</span>
                </li>
                <li>
                  <label>Product Description</label>
                  <span>{estData?.description ? estData?.description : "-"}</span>
                </li>
              </InfoListWrapper>
              <Row>
                <Col span={12}>
                  <InputLevel savedValues={savedValues} estimateLevelData={estLevelData} complexityData={complexityData} estData={estimateData} />
                </Col>
                <Col span={12}>
                  <ResultsFormContainer>
                    <h2 className="calTitle">Work Package Calculation</h2>
                    <Row className="headerRow">
                      <Col span={11}>
                        {/* <h2>Your Results</h2> */}
                      </Col>
                      <Col span={13} style={{ display: "flex" }}>
                        <h3>Default</h3>
                        <h3 style={{ flex: 2 }}>Modify</h3>
                        <h3>Result</h3>
                      </Col>
                    </Row>
                    <FormWrapper>
                      <Row>
                        <Col span={11}>
                          <label>Number of Assemblies (Main Assemblies + Sub-Assemblies) in Work Package</label>
                        </Col>
                        <Col span={13}>
                          <ResultsList>
                            <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <InputNumber disabled value={parseInt(savedValues?.assemblies_default) + parseInt(savedValues.sub_assemblies_default)} className="bgInput" />
                            </div>

                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "12px", flex: 2 }}>
                              <Button shape="circle" className="circleBtn">-</Button>
                              <InputNumber disabled value={savedValues?.sub_assemblies ? (parseInt(savedValues?.assemblies) + parseInt(savedValues.sub_assemblies)) : savedValues.assemblies} />
                              <Button shape="circle" className="circleBtn">+</Button>
                            </div>
                            <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <InputNumber
                                min={0}
                                value={savedValues?.sub_assemblies ? (parseInt(savedValues?.assemblies) + parseInt(savedValues.sub_assemblies)) : savedValues.assemblies}
                                className="bgInput"
                                disabled />
                            </div>
                          </ResultsList>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={11}>
                          <label>Approximate Number of Parts in Work Package</label>
                        </Col>
                        <Col span={13}>
                          <ResultsList>
                            <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <InputNumber disabled value={savedValues?.parts_default} className="bgInput" />
                            </div>

                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "12px", flex: 2 }}>
                              <Button shape="circle" className="circleBtn">-</Button>
                              <InputNumber disabled value={savedValues.parts} />
                              <Button shape="circle" className="circleBtn">+</Button>
                            </div>
                            <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <InputNumber value={savedValues.parts} min={0} disabled className="bgInput" />
                            </div>
                          </ResultsList>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={10}>
                          <label>Number of Estimates in Work Package</label>
                        </Col>
                        <Col span={14}>
                          <ResultsList>
                            <div style={{ flex: 1, visibility: "hidden" }}>
                              <Input disabled />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "12px", flex: 2, visibility: "hidden" }}>
                              <Button shape="circle" className="circleBtn">-</Button>
                              <span>0</span>
                              <Button shape="circle" className="circleBtn">+</Button>
                            </div>
                            <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <InputNumber disabled value={savedValues.estimates} className="bgInput" />
                            </div>
                          </ResultsList>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={10}>
                          <label>Expected Work Package Delivery (Days)</label>
                        </Col>
                        <Col span={14}>
                          <ResultsList>
                            <div style={{ flex: 1, visibility: "hidden" }}>
                              <Input disabled />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "12px", flex: 2, visibility: "hidden" }}>
                              <Button shape="circle" className="circleBtn">-</Button>
                              <span>0</span>
                              <Button shape="circle" className="circleBtn">+</Button>
                            </div>
                            <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <InputNumber disabled value={savedValues.days} className="bgInput" />
                            </div>
                          </ResultsList>
                        </Col>
                      </Row>
                    </FormWrapper>
                    <TotalCostWrapper>
                      {/* <img src={PaymentImg} alt="cost" /> */}
                      <h5>Budget Work Package Cost</h5>
                      <h4>€ {savedValues.cost !== "NaN" ? savedValues.cost : 0}</h4>
                    </TotalCostWrapper>
                  </ResultsFormContainer>
                  {/* <ResultsForm formData={savedValues} estimateLevelData={estLevelData} complexityData={complexityData} settingsData={settingsData} prevValues={prevStateValues} getOutput={(data) => setPrevStateValues(data)} /> */}
                </Col>
              </Row>
              <Row style={{ margin: "40px 0px 20px" }}>
                <Col span={12}>
                  <div style={{ padding: "16px 28px", paddingLeft: "0px", borderRight: "1px solid rgba(70, 107, 164, 0.2)" }}>
                    <LabelItem>Uploaded Documents</LabelItem>
                    {
                      estData && estData.files?.length > 0 &&
                      estData.files.filter(fileObj => fileObj.types === 'Document').map(fileItem =>
                        <ListItem key={fileItem.id}>
                          <span className="icon">
                            <FileIcon extension={get_url_extension(fileItem.s3_url)} {...defaultStyles[get_url_extension(fileItem.s3_url)]} />
                          </span>
                          <h4 className="name">{fileItem.s3_url.split('/').pop().split('#')[0].split('?')[0]}</h4>
                          <a href={fileItem.s3_url} download>
                            <img src={DownloadIcon} alt="download" />
                          </a>
                        </ListItem>
                      )
                    }
                    {
                      estData && (estData.files?.length === 0 || estData.files.filter(fileObj => fileObj.types === 'Document').length === 0) && <div>There are no uploaded documents</div>
                    }
                  </div>
                </Col>
                <Col span={12}>
                  <div style={{ padding: "16px 28px" }}>
                    <LabelItem>Uploaded Drawings</LabelItem>
                    {
                      estData && estData.files?.length > 0 &&
                      estData.files.filter(fileObj => fileObj.types === 'Drawing').map(fileItem =>
                        <ListItem key={fileItem.id}>
                          <span className="icon">
                            <FileIcon extension={get_url_extension(fileItem.s3_url)} {...defaultStyles[get_url_extension(fileItem.s3_url)]} />
                          </span>
                          <h4 className="name">{fileItem.s3_url.split('/').pop().split('#')[0].split('?')[0]}</h4>
                          <a href={fileItem.s3_url} download>
                            <img src={DownloadIcon} alt="download" />
                          </a>
                        </ListItem>
                      )
                    }
                    {
                      estData && (estData.files?.length === 0 || estData.files.filter(fileObj => fileObj.types === 'Drawing').length === 0) && <div>There are no uploaded drawings</div>
                    }
                  </div>
                </Col>
              </Row>
            </div>
        }
      </Container>
    </>
  )
};

const Container = styled.div`
  padding: 24px;
`;

const Backwrapper = styled.div`
  a {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: #A1AAB4;
    text-decoration: none;
    text-transform: uppercase;
    img {
      width: 24px;
      height: 24px;
    }
  }
`;

const InfoListWrapper = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  li {
    padding-bottom: 12px;
    display: grid;
    grid-template-columns: 20% 80%;
    align-items: center;
    gap: 16px;
    label {
      font-family: ${palette.FONT_FAMILY};
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase;
      color: #242E4C;
      line-height: 20px;
    }
    span {
      font-family: ${palette.FONT_FAMILY};
      font-weight: 400;
      font-size: 14px;
      color: #242E4C;
    }
  }
`;

const StatusText = styled.span`
  padding: 5px;
  border-radius: 6px;
  text-align: center;
  display: inline-block;
  width: fit-content;
  text-transform: capitalize;
  background: ${({ status }) => status === "reviewed" ? "#FFEBEB" : status === "submitted" ? "#F7F3E4" : "#E4FAEE"};
  color: ${({ status }) => status === "reviewed" ? "#AB1717" : status === "submitted" ? "#B9A855" : "#489775"};
`;

const AssigneeDetails = styled.span`
  background: #A6C4FF;
  color: #1563FC;
  padding: 5px;
  border-radius: 6px;
  text-align: center;
  display: inline-block;
  width: fit-content;
`;

const EditWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  .ant-btn {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    &:hover, &:focus, &:active {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }
    img {
      width: 15px;
      height: 15px;
    }
  }
  .tickIcon svg {
    fill: #466BA4;
  }
`;
