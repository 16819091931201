import { Button, Form, Input, InputNumber } from "antd"
import { useEffect, useState } from "react";
import styled from "styled-components"
import * as palette from '../../styles/variables';
import { updateEstimtedTime } from "../../utils/services/estimated-time";

export const EditEstimatedTime = ({ data, cancel, updatedData }) => {

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        setup_job: data.setup_job,
        job_evalution: data.job_evalution,
        // data_available: data.data_available,
        report_to_client_base: data.report_to_client_base,
        report_to_client_estimate: data.report_to_client_estimate,
        build_bom_base: data.build_bom_base,
        build_bom_estimate: data.build_bom_estimate,
        make_estimate: data.make_estimate,
        quality_assurance_base: data.quality_assurance_base,
        quality_assurance_estimate: data.quality_assurance_estimate,
        contingency: data.contingency,
        efficiency: data.efficiency,
        rate: data.rate,
        process_component: data.process_component,
      })
    }
  }, [data])

  const onFinish = (values) => {
    setLoading(true);
    updateEstimtedTime(
      values,
      (res) => {
        setLoading(false);
        updatedData(values)
        cancel();
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  return (
    <FormContainer>
      <Form
        form={form}
        name="edit estimate time"
        colon={false}
        requiredMark={false}
        labelCol={{ span: 24 }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className="formItems">

          <Form.Item
            label="Set Up Job (mins)"
            name="setup_job"
            rules={[
              {
                required: true,
                message: "Please enter the value"
              }
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>


          <Form.Item
            label="Job Evaluation (Mins/Estimate)"
            name="job_evalution"
            rules={[
              {
                required: true,
                message: "Please enter the value"
              }
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>

          <Form.Item
            label="Build a BOM (Mins)"
            name="build_bom_base"
            rules={[
              {
                required: true,
                message: "Please enter the value"
              }
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>

          <Form.Item
            label="Build a BOM Estimate (Mins/Estimate)"
            name="build_bom_estimate"
            rules={[
              {
                required: true,
                message: "Please enter the value"
              }
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>

          {/* <Form.Item
            label="Data Available"
            name="data_available"
            rules={[
              {
                required: true,
                message: "Please enter the value"
              }
            ]}
          >
            <InputNumber min={0} />
          </Form.Item> */}

          <Form.Item
            label="Report to client (Mins)"
            name="report_to_client_base"
            rules={[
              {
                required: true,
                message: "Please enter the value"
              }
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>

          <Form.Item
            label="Report to client estimate (Mins/Estimate)"
            name="report_to_client_estimate"
            rules={[
              {
                required: true,
                message: "Please enter the value"
              }
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>

          <Form.Item
            label="Make Estimate (Mins/Estimate)"
            name="make_estimate"
            rules={[
              {
                required: true,
                message: "Please enter the value"
              }
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>

          <Form.Item
            label="Aqality Assurance - Base (Mins)"
            name="quality_assurance_base"
            rules={[
              {
                required: true,
                message: "Please enter the value"
              }
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>

          <Form.Item
            label="Aqality Assurance - per Estimate (Mins/Estimate)"
            name="quality_assurance_estimate"
            rules={[
              {
                required: true,
                message: "Please enter the value"
              }
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>

          <Form.Item
            label="Contingency(%)"
            name="contingency"
            rules={[
              {
                required: true,
                message: "Please enter the value"
              }
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>

          <Form.Item
            label="Efficiency(%)"
            name="efficiency"
            rules={[
              {
                required: true,
                message: "Please enter the value"
              }
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>

          <Form.Item
            label="Hourly rate (€/hr)"
            name="rate"
            rules={[
              {
                required: true,
                message: "Please enter the value"
              }
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>

          <Form.Item
            label="Typical no of processes per component "
            name="process_component"
            rules={[
              {
                required: true,
                message: "Please enter the value"
              }
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>
        </div>
        <div className="footerBtn">
          <Form.Item>
            <Button className="outlinedBtn" onClick={cancel}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" className="primaryBtn" htmlType="submit" style={{ padding: "0px 24px" }} disabled={loading}>
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </FormContainer>
  )
}

const FormContainer = styled.div`
  padding: 20px 0px;
  .formItems {
    display: grid;
    grid-template-columns: auto auto auto;
    @media only screen and (max-width: 769px) {
      grid-template-columns: auto auto;
    }
  }
  .footerBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 20px 30px 0px;
    gap: 20px;
    .ant-form-item {
      margin-bottom: 0px;
    }
  }
`;
