export const BASE_URL = 'https://api.productcostoptimisation.co.uk/api/v1';
// export const BASE_URL = 'http://localhost:3001/api/v1';

//quotulator
export const QUOTULATOR_URL = 'quotulator';

// complexity
export const COMPLEXITY_URL = 'settings/complexity';
export const SETTING_URL = 'settings';
export const CUSTOMERS_URL = 'customer';
export const USERS_URL = 'users';
export const BOM_BUILD_URL = 'settings/bomBuild';
export const ESTIMATE_LEVEL_URL = 'settings/estimateLevel';
export const AVAILABLE_DATA_URL = 'settings/availableData';

