import { Table, Tooltip } from "antd";
import { NavBar } from "../../components/navigation";
import styled from "styled-components";
import * as palette from "../../styles/variables";
import moment from "moment";
import EditImg from "../../images/edit.svg";
import PlusImg from "../../images/plus.svg";
import DeleteImg from "../../images/delete.svg";
import { useEffect, useState } from "react";
import { getAllComplexity, deleteComplexity } from "../../utils/services/complexity";
import { Loader } from "../../components/Loader";
import swal from "sweetalert";
import { AddComplexity } from "./AddComplexity";
import { EditComplexity } from "./EditComplexity";
import { Modal } from "antd";
import { AddEstimateLevel } from "./AddEstimateLevel";
import { EditEstimateLevel } from "./EditEstimateLevel";
import { getAllEstimateLevel, deleteEstimateLevel } from "../../utils/services/multiplier";
import { AvailableData } from "../AvailableData/index"
// complexity
export const Complexity = () => {
  const [loading, setLoading] = useState(true);

  const [complexityList, setComplexityList] = useState([]);
  const [isAddComplexityModalOpen, addComplexityModalOpen] = useState(false);
  const [isEditComplexityModalOpen, editComplexityModalOpen] = useState(false);
  const [dataItem, setDataItem] = useState(null)

  const [isEditEstimateLevelModalOpen, setEditEstimateLevelModalOpen] = useState(false);
  const [isAddEstimateLevelModalOpen, setAddEstimateLevelIsModalOpen] = useState(false);
  const [estimateLevelList, setEstimateLevelList] = useState([]);
  const [dataItemEst, setDataItemEst] = useState(null)

  const AddShowModal = () => {
    addComplexityModalOpen(true);
  };
  const addhandleOk = () => {
    addComplexityModalOpen(false);
  };
  const addhandleCancel = () => {
    addComplexityModalOpen(false);
  };

  const updatedAddData = (values) => {
    // const complexityList = complexityList.push(values);
    // setComplexityList(complexityList);
    setComplexityList([values, ...complexityList]);
  };

  const editShowModal = (data) => {
    setDataItem(data);
    editComplexityModalOpen(true);
  };
  const edithandleOk = () => {
    editComplexityModalOpen(false);
  };
  const edithandleCancel = () => {
    editComplexityModalOpen(false);
  };

  const updatedEditData = (values) => {
    const complexityListCopy = complexityList.map((items) =>
      items.id === values.id ? { ...complexityList, ...values } : items
    );
    setComplexityList(complexityListCopy);
  };


  // Add Estimate level
  // Add estimate level multipliers
  const addEstimateLevelModal = () => {
    setAddEstimateLevelIsModalOpen(true);
  };
  const addEstimateLevelhandleOk = () => {
    setAddEstimateLevelIsModalOpen(false);
  };
  const addEstimateLevelhandleCancel = () => {
    setAddEstimateLevelIsModalOpen(false);
  };

  const addUpdateDataEstimateLevel = (values) => {
    swal("Estimate Level has been created!", {
      icon: "success",
    });
    // const estimateLevel = estimateLevelList.push(values);
    // setAddEstimateLevelIsModalOpen(estimateLevel);
    setEstimateLevelList([values, ...estimateLevelList]);
  };

  // Estimate Levels
  useEffect(() => {
    getAllEstimateLevel(
      (res) => {
        setEstimateLevelList(res.data);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  }, []);

  // Edit estimate level muliplier
  const showEstimateLevelModal = (data) => {
    setDataItemEst(data)
    setEditEstimateLevelModalOpen(true);
  };
  const editEstimateLevelhandleOk = () => {
    setEditEstimateLevelModalOpen(false);
  };
  const editEstimateLevelhandleCancel = () => {
    setEditEstimateLevelModalOpen(false);
  };

  const updateDataEstimateLevel = (values) => {
    const estimateLevelListCopy = estimateLevelList.map((items) =>
      items.id === values.id ? { ...estimateLevelList, ...values } : items
    );
    swal("Estimate Level has been updated!", {
      icon: "success",
    });
    setEstimateLevelList(estimateLevelListCopy);
  };


  const handleComplexityEdit = (record) => {
    var data = {
      complexity_name: record.complexity_name,
        examples: record.examples,
        response: record.response,
        assemblies: record.assemblies,
        sub_assemblies: record.sub_assemblies,
        no_parts: record.no_parts,
        id: record.id,
        s3_url: record.s3_url
    };
    editShowModal(data);
  };

  // delete data  
  const handleDeleteClick = (record) => {
    swal({
      title: record.complexity_name,
      text:
        "Are you sure you want to delete this complexity?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((willDelete) => {
      if (willDelete) {
        var data = {
          id: record.id
        };
        deleteComplexity(
          data,
          success => {
            const updatedList = complexityList.filter(item => item.id !== record.id);
            setComplexityList(updatedList)
          },
          errorMessages => {}
        );
        swal("Complexity has been deleted!", {
          icon: "success",
        });
      } else {
        // swal("Your imaginary file is safe!");
      }
    })
  }


  useEffect(() => {
    getAllComplexity(
      (res) => {
        setComplexityList(res.data);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  }, []);

  // Estimate Level Delete

  const handleEstimateDeleteClick = (record) => {
    swal({
      text: "Are you sure you want to delete this estimate level?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((willDelete) => {
      if (willDelete) {
        var data = {
          id: record.id,
        };
        deleteEstimateLevel(
          data,
          (success) => {
            const updatedList = estimateLevelList.filter(
              (item) => item.id !== record.id
            );
            setEstimateLevelList(updatedList);
          },
          (errorMessages) => {}
        );
        swal("Estimate Level has been deleted!", {
          icon: "success",
        });
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
  };

  // Edit estimate level

  const handleEstimateLevelEdit = (record) => {
    var data = {
      id: record.id,
      name: record.name,
      multiplier: record.multiplier,
      estimate_level: record.estimate_level,
      response: record.response,
    };
    showEstimateLevelModal(data);
  };

  const columns = [
    {
      title: "S.NO",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      width: "5%",
      render: (index,text, record) => (
         record + 1
      ),
    },

    {
      title: "Complexity",
      dataIndex: "complexity_name",
      key: "complexity_name",
      ellipsis: true,
      width: "20%"
    },
    {
      title: "Examples",
      dataIndex: "examples",
      key: "examples",
      ellipsis: true,
      width: "10%"
    },
    {
      title: "Pictures",
      dataIndex: "s3_url",
      key: "s3_url",
      render: (text, record) => (
        <img src={text} style={{ width: "37px" }} alt="view" />
      ),
      width: "7%"
    },
    {
      title: "No of Assemblies",
      dataIndex: "assemblies",
      key: "assemblies",
      ellipsis: false,
      width: "8%",
      "text-overflow": "ellipsis"
      //   render: (text) => <span>{moment(text).format('DD MMM')}</span>
    },
    {
      title: "No of Sub Assemblies",
      dataIndex: "sub_assemblies",
      key: "sub_assemblies",
      ellipsis: false,
      width: "8%"
      //   render: (text) => <span>${text}</span>
    },
    {
      title: "No Parts",
      dataIndex: "no_parts",
      key: "no_parts",
      ellipsis: false,
      width: "5%"
    },
    {
      title: "Edit",
      key: "view",
      align: "center",
      render: (_, record) => (
        <Tooltip placement="top" title="Edit" onClick={() => handleComplexityEdit(record)}>
          <ActionItem style={{ margin: "0px 12px" }}>
            <img src={EditImg} style={{ width: "17px" }} alt="edit" />
          </ActionItem>
        </Tooltip>
      ),
      width: "1%"
    },
    {
      title: "Delete",
      key: "delete",
      align: "center",
      render: (_, record) => (
        <Tooltip placement="top" title="Delete"  onClick={()=>handleDeleteClick(record)}>
          <ActionItem style={{ margin: "0px 15px" }}>
            <img src={DeleteImg} style={{ width: "17px" }} alt="delete" />
          </ActionItem>
        </Tooltip>
      ),
      width: "1%"
    },
  ];


  // Estimate Level columns
  const columns2 = [
    {
      title: "S.NO",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      width: "5%",
      render: (index,text, record) => (
        record + 1
     ),
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: "40%",
    },

    // {
    //   title: "Response",
    //   dataIndex: "response",
    //   key: "response",
    //   ellipsis: true,
    //   width: "40%",
    // },
   
    {
      title: "Edit",
      key: "view",
      align: "center",
      render: (_, record) => (
        <Tooltip
          placement="top"
          title="Edit"
          onClick={() => handleEstimateLevelEdit(record)}
        >
          <ActionItem style={{ margin: "0px 12px" }}>
            <img src={EditImg} style={{ width: "17px" }} alt="edit" />
          </ActionItem>
        </Tooltip>
      ),
    },
    {
      title: "Delete",
      key: "delete",
      align: "center",
      render: (_, record) => (
        <Tooltip
          placement="top"
          title="Delete"
          onClick={() => handleEstimateDeleteClick(record)}
        >
          <ActionItem style={{ margin: "0px 15px" }}>
            <img src={DeleteImg} style={{ width: "17px" }} alt="delete" />
          </ActionItem>
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <NavBar />

      <br></br>
      <p style={{ "margin-left": "4vh", "font-weight": 600 }}>
        Estimate Level
        <span
          style={{ float: "right", "margin-right": "6vh", cursor: "pointer" }}
          onClick={addEstimateLevelModal}
        >
          <img src={PlusImg} alt=""></img>
        </span>{" "}
      </p>
      <TableWrapper>
        {loading ? (
          <Loader />
        ) : (
          <Table
            columns={columns2}
            dataSource={estimateLevelList}
            scroll={{ x: true, y: true }}
            pagination={{
              position: ["bottomRight"],
              pageSize: 10,
            }}
          />
        )}
      </TableWrapper>

      <div>
      <p style={{ "margin-left": "4vh", "font-weight": 600 }}>
      Complexity
        <span style={{ float: "right", "margin-right": "6vh", cursor:"pointer" }}  onClick={AddShowModal}>
          <img src={PlusImg} alt=""></img>
        </span>
      </p>
        
      </div>
      <TableWrapper>
        {loading ? (
          <Loader />
        ) : (
          <Table
            columns={columns}
            dataSource={complexityList}
            scroll={{ x: true, y: true }}
            pagination={{
              position: ["bottomRight"],
              pageSize: 10,
            }}
          />
        )}
      </TableWrapper>


          <AvailableData />
       {/* Add */}
       <Modal
        open={isAddComplexityModalOpen}
        title="Add Complexity"
        centered={true}
        footer={null}
        onOk={addhandleOk}
        onCancel={addhandleCancel}
        style={{
          top: 20,
        }}
        width={900}
        destroyOnClose={true}
        className="editModal"
      >
        <AddComplexity
          cancel={addhandleCancel}
          updatedData={updatedAddData}
        />
      </Modal>


      {/* Edit */}
      <Modal
        open={isEditComplexityModalOpen}
        title="Edit Complexity"
        centered={true}
        footer={null}
        onOk={edithandleOk}
        onCancel={edithandleCancel}
        style={{
          top: 20,
        }}
        width={900}
        destroyOnClose={true}
        className="editModal"
      >
        <EditComplexity
          data={dataItem}
          cancel={edithandleCancel}
          updatedData={updatedEditData}
        />
      </Modal>

        {/* Add Estimate */}
      <Modal
        open={isAddEstimateLevelModalOpen}
        title="Add Estimate Level"
        centered={true}
        footer={null}
        onOk={addEstimateLevelhandleOk}
        onCancel={addEstimateLevelhandleCancel}
        style={{
          top: 0,
        }}
        width={600}
        destroyOnClose={true}
        className="editModal"
      >
        <AddEstimateLevel
          cancel={addEstimateLevelhandleCancel}
          updatedData={addUpdateDataEstimateLevel}
        />
      </Modal>
      
        {/* Edit Estimate */}
      <Modal
        open={isEditEstimateLevelModalOpen}
        title="Edit Estimate Level"
        centered={true}
        footer={null}
        onOk={editEstimateLevelhandleOk}
        onCancel={editEstimateLevelhandleCancel}
        style={{
          top: 20,
        }}
        width={600}
        destroyOnClose={true}
        className="editModal"
      >
        <EditEstimateLevel
          data={dataItemEst}
          cancel={editEstimateLevelhandleCancel}
          updatedData={updateDataEstimateLevel}
        />
      </Modal>
    </>
  );
};

const TableWrapper = styled.div`
  padding: 25px;
  .ant-table {
    border-radius: ;
  }
  .ant-table-thead .ant-table-cell {
    background: transparent;
    border-bottom: 1px solid ${palette.WHITE_SMOKE};
    text-transform: uppercase;
    font-family: ${palette.FONT_FAMILY};
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: ${palette.PRIMARY_COLOR};
    padding: 12px 7px !important;
    &:before {
      display: none;
    }
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    border-bottom: none;
    padding: 12px 7px !important;
    font-family: ${palette.FONT_FAMILY};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: ${palette.PRIMARY_COLOR};
  }
`;

const ActionItem = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
