import React from "react"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import * as palette from '../../styles/variables';
// import { Search } from "../inputs";
// import NotifyIcon from "../../images/bell.svg"
import ProfilePicture from "../../images/user.png"
import { ProfilePic } from "../ProfilePic";
import { Button, Dropdown } from "antd";

const title = (location) => {
  switch (location.pathname) {
    case "/":
      return "Estimation"
    case "/complexity":
      return "Estimate Level & Complexity & Available Data"
    case "/customers":
      return "Customer details"
    case "/estimated-time":
      return "Estimate time calculation"
    case "/assignee":
      return "Assignee details"
    default:
      return "CMS"
  }
}

export const NavBar = () => {

  const location = useLocation();

  const items = [
    {
      key: '1',
      label: (
        <a href="" onClick={() => {sessionStorage.removeItem('token'); sessionStorage.removeItem('userId');}}>
          Logout
        </a>
      ),
    },
  ]

  return (
    <NavBarWrapper>
      <p>{title(location)}</p>
      <div style={{ display: "flex", alignItems: "center", gap: 24 }}>
        {/* <Search placeholder="Search..."/> */}
        <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
          {/* <NotificationWrapper>
            <img src={NotifyIcon} alt="notification" />
          </NotificationWrapper> */}
          <Dropdown
            menu={{
              items,
            }}
            trigger={['click']}
          >
            <a style={{cursor: "pointer"}} onClick={(e) => e.preventDefault()}>
              <ProfilePic src={ProfilePicture} />
            </a>
          </Dropdown>
        </div>
      </div>
    </NavBarWrapper>
  )
}

const NavBarWrapper = styled.div`
  display: flex;
  height: 58px;
  align-items: center;
  justify-content: space-between;
  padding: 12px 25px;
  background: ${palette.WHITE_COLOR};
  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: ${palette.PRIMARY_COLOR};
  }
`

const NotificationWrapper = styled.div`
  background: ${palette.APP_BACKGROUND_COLOR};
  width: 32px;
  height: 32px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 20px;
    height: 20px;
  }
`;
