// fonts
export const FONT_FAMILY = 'Poppins, sans-serif';
export const CONTENT_FONT_FAMILY = 'Poppins, sans-serif'

// brand colors
export const APP_BACKGROUND_COLOR = '#F8FBFF';
export const PRIMARY_COLOR = '#242E4C';
export const MAIN_COLOR = "#466BA4";

// colors
export const WHITE_COLOR = '#FFFFFF';
export const BLACK_COLOR = '#000000';
export const STEEL = '#2B2B2A';
export const ALICE_BLUE = '#F8FAFB';
export const DARK_GRAY = '#ACACAC';
export const WHITE_SMOKE = '#F6F6F6';
