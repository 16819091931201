import { Button, Col, Form, Input, Row } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components"
import BgImage from "../../images/loginBg.png"
import LogoImage from "../../images/loginLogo.svg"
import MailIcon from "../../images/mail.svg"
import LockIcon from "../../images/unlock.svg"
import * as palette from "../../styles/variables";
import { loginUser } from "../../utils/services/users";

export const LoginPage = () => {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)

  const onFinish = (values) => {
    setLoading(true)
    loginUser(
      values,
      (success) => {
        sessionStorage.setItem('token', success?.tokens?.accessToken)
        sessionStorage.setItem('userId', success?.user?.data?.id)
        sessionStorage.setItem('role', success?.user?.data?.role)
        setLoading(false)
        navigate("/")
      },
      (err) => {
        setLoading(false)
      }
    )
  };

  return (
    <Container>
      <Row className="rowWrapper">
        <Col span={12}>
          <LogoWrapper>
            <img src={LogoImage} alt="logo" />
          </LogoWrapper>
        </Col>
        <Col span={12}>
          <FormContainer>
            <h3>Admin Login</h3>
            <Form
              name="login"
              labelCol={{
                span: 24,
              }}
              requiredMark={false}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="User ID"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your user ID!',
                  },
                ]}
                className="emailItem"
              >
                <Input addonBefore={<img src={MailIcon} alt="email" />} />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your password!',
                  },
                ]}
              >
                <Input.Password addonBefore={<img src={LockIcon} alt="password" />} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="loginBtn" disabled={loading}>
                  Login
                </Button>
              </Form.Item>
            </Form>
          </FormContainer>
        </Col>
      </Row>
      <BgWrapper>
        <img src={BgImage} alt="background" />
      </BgWrapper>
    </Container>
  )
}

const Container = styled.div`
  height: 100%;
  .rowWrapper {
    height: 100%;
    align-items: center;
    justify-content: center;
  }
`;

const BgWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  img {
    height: 100%;
    object-fit: cover;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 30px;
  img {
    max-width: 320px;
    height: 150px;
  }
`;

const FormContainer = styled.div`
  padding: 30px 50px;
  border-left: 1px solid #DEDEDE;
  max-width: 469px;
  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: ${palette.WHITE_COLOR};
    text-transform: uppercase;
    margin-bottom: 30px;
  }
  .ant-form-item .ant-form-item-label >label {
    height: 27px;
    color: ${palette.WHITE_COLOR};
    font-weight: 400;
    font-size: 18px;
    font-family: ${palette.FONT_FAMILY};
  }
  .ant-input-group .ant-input-group-addon {
    padding: 0px 14px;
    background-color: ${palette.WHITE_COLOR};
    img {
      width: 25px;
      height: 25px;
    }
  }
  .emailItem .ant-input-group .ant-input, .ant-input-affix-wrapper {
    height: 48px;
    border-color: #DEDEDE !important;
    font-weight: 400;
    font-size: 18px;
    color: #3C3A3A;
    box-shadow: none !important;
    &:hover, &:focus, &:active {
      border-color: #DEDEDE !important;
      box-shadow: none !important;
    }
  }
  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    border-color: #ff4d4f !important;
  }
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    border-color: #ff4d4f !important;
  }
  .loginBtn {
    width: 100%;
    margin-top: 20px;
    height: 56px;
    background: rgba(255, 255, 255, 0.2) !important;
    border-radius: 5px;
    font-weight: 400;
    font-size: 24px !important;
    color: ${palette.WHITE_COLOR} !important;
    font-family: ${palette.FONT_FAMILY};
    box-shadow: none !important;
    &:hover, &:focus, &:active {
      background: rgba(255, 255, 255, 0.2) !important;
      color: ${palette.WHITE_COLOR} !important;
      box-shadow: none !important;
    }
  }
`;
