import { BASE_URL, USERS_URL } from '../helper';
import logger from '../logger';
import Interceptor from './interceptor';

const request = new Interceptor();

export const getUsers = async (success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.get(`${BASE_URL}/${USERS_URL}`, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};


export const deleteUsers = async (data, success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.delete(`${BASE_URL}/${USERS_URL}/${data.id}`, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};


export const addUser = async (data, success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.post(`${BASE_URL}/${USERS_URL}`, data, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};

export const updateUser = async (data, success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.put(`${BASE_URL}/${USERS_URL}`, data, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};