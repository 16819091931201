import { BASE_URL, COMPLEXITY_URL } from '../helper';
import logger from '../logger';
import Interceptor from './interceptor';

const request = new Interceptor();

export const getAllComplexity = async (success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.get(`${BASE_URL}/${COMPLEXITY_URL}`, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};


export const deleteComplexity = async (data, success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.delete(`${BASE_URL}/${COMPLEXITY_URL}/${data.id}`, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};

export const addComplexity = async (data, success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.post(`${BASE_URL}/${COMPLEXITY_URL}`, data, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};

export const updateComplexity = async (data, success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.put(`${BASE_URL}/${COMPLEXITY_URL}`, data, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};