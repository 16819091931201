import { Button, Form, Input, InputNumber } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import * as palette from "../../styles/variables";
import { addEstimateLevelBuild } from "../../utils/services/multiplier";

export const AddEstimateLevelMultiplier = ({
  data,
  cancel,
  updatedAddData,
}) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
        multiplier: data.multiplier,
        estimate_level: data.estimate_level,
      });
    }
  }, [data]);

  const onAddBom = (values) => {
    setLoading(true);
    addEstimateLevelBuild(
      values,
      (res) => {
        setLoading(false);
        updatedAddData(res.data);
        cancel();
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  return (
    <FormContainer>
      <Form
        form={form}
        name="add estimate level"
        colon={false}
        requiredMark={false}
        labelCol={{ span: 24 }}
        onFinish={onAddBom}
        autoComplete="off"
      >
        <div className="formItems">
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter the estimate name",
                placeholder: "Basic usage",
              },
            ]}
          >
            <Input
              placeholder="Enter the estimate name"
              style={{
                width: "98%",
              }}
            />
          </Form.Item>

          <Form.Item
            label="Estimate Level"
            name="estimate_level"
            rules={[
              {
                required: true,
                message: "Please enter the value",
              },
            ]}
          >
            <InputNumber
              min={0}
              style={{
                width: "98%",
              }}
              placeholder="Enter the value"
            />
          </Form.Item>

          <Form.Item
            label="Multiplier"
            name="multiplier"
            rules={[
              {
                required: true,
                message: "Please enter the value",
              },
            ]}
          >
            <InputNumber
              min={0}
              style={{
                width: "98%",
              }}
              placeholder="Enter the value"
            />
          </Form.Item>
        </div>
        <div className="footerBtn">
          <Form.Item>
            <Button className="outlinedBtn" onClick={cancel}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              className="primaryBtn"
              htmlType="submit"
              style={{ padding: "0px 24px" }}
              disabled={loading}
            >
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  padding: 20px 0px;
  .formItems {
    display: grid;
    grid-template-columns: auto auto auto;
    @media only screen and (max-width: 769px) {
      grid-template-columns: auto auto;
    }
  }
  .footerBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 20px 30px 0px;
    gap: 20px;
    .ant-form-item {
      margin-bottom: 0px;
    }
  }
`;
