import { Button, Carousel, Col, Form, Modal, Radio, Row, Select, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import { DataMsgWrapper, FormContainer, SliderImg } from "./styles";
import { RightOutlined } from '@ant-design/icons';
import { ReactComponent as ConceptImg } from '../../images/quotulator/request_quote.svg'
import { ReactComponent as ConceptImg2 } from '../../images/quotulator/ads_click.svg'
import { ReactComponent as ConceptImg3 } from '../../images/quotulator/draw.svg'
import Icon from '@ant-design/icons';

export const InputLevel = (props) => {

  const [form] = Form.useForm();

  const carouselRef = useRef();

  const [complexityObj, setComplexityObj] = useState(null);

  console.log(props, "props")

  useEffect(() => {
    if (props.savedValues) {
      var elementPos = props.complexityData.findIndex(obj => obj.id === parseInt(props.savedValues.complexity))
      carouselRef.current.goTo(elementPos)
      const getObj = props.complexityData.find(item => item.id === parseInt(props.savedValues.complexity))
      if (getObj) {
        setComplexityObj(getObj ? getObj : null);
      }
    }
  }, [props.savedValues])

  return (
    <FormContainer>
      <Form
        name="basic"
        form={form}
        layout="vertical"
        style={{
          // margin: "30px auto 0px",
          width: "100%",
        }}
        initialValues={{
          level: props.savedValues ? parseInt(props.savedValues.level) : 0,
          complexity: props.savedValues ? parseInt(props.savedValues.complexity) : null,
          estimateData: props.savedValues ? parseInt(props.savedValues.estimateData) : null
        }}
        autoComplete="off"
        requiredMark={false}
      >
        <Form.Item
          label="Required Estimate Level"
          name="level"
          className="inputFormItem"
          rules={[
            {
              required: true,
              message: "Please select estimate level"
            }
          ]}>
          <Radio.Group className="radioLevelBtn" disabled>
            {
              props?.estimateLevelData.map((item, i) =>
                <Radio.Button value={item.id} key={i}>
                  <span style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "10px" }}>
                    <Icon component={i === 0 ? ConceptImg : i === 1 ? ConceptImg2 : ConceptImg3} className="radioIcon" />
                    {item.name}
                  </span>
                </Radio.Button>
              )
            }
          </Radio.Group>
        </Form.Item>
        <Row gutter={[16, 16]} style={{ paddingTop: "10px" }}>
          <Col span={12}>
            <Form.Item label="Complexity & Scope" name="complexity" className="inputFormItem" style={{ marginTop: "12px" }}
              rules={[
                {
                  required: true,
                  message: "Please select complexity"
                }
              ]}
            >
              <Select disabled className="selectInput" placeholder="Select Complexity">
                {
                  props?.complexityData.map((item, i) =>
                    <Select.Option value={item.id} key={i}>{item.complexity_name}</Select.Option>
                  )
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <div>
              <Carousel ref={carouselRef} dots={false} draggable={false} swipe={false} style={{ border: "1px solid #DBE2F1", borderRadius: "8px" }}>
                {
                  complexityObj && props?.complexityData.map((item, i) =>
                    <SliderImg key={i}>
                      {
                        item.s3_url && <span><img src={item.s3_url} alt={item.complexity_name} /></span>
                      }
                    </SliderImg>
                  )
                }
              </Carousel>
            </div>
          </Col>
        </Row>
        {
          props?.savedValues?.estimateData ?
            <Row gutter={[16, 16]} style={{ paddingTop: "40px" }}>
              <Col xs={24} md={12}>
                <Form.Item label="Data Available For Estimating" name="estimateData" className="inputFormItem" style={{ marginTop: "12px" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select data"
                    }
                  ]}
                >
                  <Select disabled className="selectInput" placeholder="Select Data">
                    {
                      props?.estData.map((item, i) =>
                        <Select.Option value={item.value} key={i}>{item.name}</Select.Option>
                      )
                    }
                  </Select>
                  {/* <Radio.Group onChange={complexityChange} className="radioGrpBtn">
                <Space direction="vertical" style={{ gap: 0 }}>
                  {
                    props?.complexityData.map((item, i) =>
                      <Radio value={item.id} key={i}>{item.complexity_name}</Radio>
                    )
                  }
                </Space>
              </Radio.Group> */}
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <DataMsgWrapper>
                  {
                    form.getFieldValue("estimateData") &&
                    <div>
                      <h4> Data matches selected Estimate Level</h4>
                    </div>
                  }
                </DataMsgWrapper>
              </Col>
            </Row> : <></>
        }
      </Form>
    </FormContainer>
  )
};