import { Menu } from "antd";
import styled from "styled-components"
import Logo from "../../images/logo.svg"
import * as palette from '../../styles/variables';
import { ReactComponent as MenuIcon } from "../../images/menu.svg"
import { ReactComponent as SettingsIcon } from "../../images/settings.svg"
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const SideBar = () => {

  const navigate = useNavigate();

  const [selectedKeys, setSelectedKeys] = useState("estimation");

  const onClick = (e) => {
    setSelectedKeys(e.key)
    if (e.key === "estimation") {
      navigate("/")
    } else {
      navigate("/" + e.key)
    }
  };

  function getItem(label, key, icon, children, type) {
    if (key === "assignee" && sessionStorage.getItem("role") === "Assignee") {
      return null
    } else {
      return {
        key,
        icon,
        children,
        label,
        type,
      };
    }
  }
  const items = [
    getItem('Estimation', 'estimation', <MenuIcon />),
    getItem('CMS', 'cms', <SettingsIcon />, [
      getItem('Complexity', 'complexity'),
      getItem('Estimated Time', 'estimated-time'),
      getItem('Multiplier', 'multiplier'),
      getItem('Assignee', 'assignee'),
      getItem('Customers', 'customers'),
    ]),
  ];

  return (
    <SideBarWrapper>
      <LogoWrapper>
        <img src={Logo} alt="logo" />
        <span>Product Cost Optimisation Ltd</span>
      </LogoWrapper>
      <NavMenuWrapper>
        <span className="dashboard">Dashboard</span>
        <Menu
          onClick={onClick}
          openKeys={(['cms'])}
          defaultOpenKeys={['cms']}
          mode="inline"
          items={items}
          expandIcon={<></>}
          selectedKeys={selectedKeys}
        />
      </NavMenuWrapper>
    </SideBarWrapper>
  )
}

const SideBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  background-color: ${palette.WHITE_COLOR};
  min-width: 200px;
  border-right: 1px solid rgba(227, 231, 241, 0.8);
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 20px 8px;
  border-bottom: 1px solid rgba(227, 231, 241, 0.8);
  width: 100%;
  img {
    width: 43px;
    height: 32px;
  }
  span {
    font-weight: 600;
    font-size: 9px;
    line-height: 10px;
    letter-spacing: -0.02em;
    color: ${palette.STEEL};
  }
`;

const NavMenuWrapper = styled.div`
  width: 100%;
  .dashboard {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #565655;
    padding-left: 16px;
    padding-bottom: 8px;
  }
  .ant-menu-item, .ant-menu-submenu-title {
    border-radius: 0px;
    width: 100% !important;
    margin: 4px 0px !important;
    padding-left: 16px !important;
  }
  .ant-menu-sub {
    background: transparent !important;
    margin-left: 26%;
    position: relative;
    &:before {
      content: "";
      width: 1px;
      height: calc(100% - 24px);
      position: absolute;
      left: 0;
      background: #A6A6A6;
    }
    .ant-menu-item {
      padding-left: 24px !important;
      position: relative;
      &:before {
        content: "";
        width: 13px;
        height: 1px;
        position: absolute;
        left: 0;
        background: #A6A6A6;
      }
    }
  }
  .ant-menu-submenu-title {
    margin-bottom: 0 !important;
  }
  .ant-menu-sub .ant-menu-item-selected {
    border-right: 0px;
    background: transparent !important;
  }
  .ant-menu-item-selected, .ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title {
    color: ${palette.MAIN_COLOR};
    background: #EAF5FE;
    border-right: 2px solid ${palette.MAIN_COLOR};
    svg path {
      stroke: ${palette.MAIN_COLOR};
    }
  }
`;
