import { Table, Tooltip } from "antd";
import { NavBar } from "../../components/navigation";
import styled from "styled-components";
import * as palette from "../../styles/variables";
import moment from "moment";
import EditImg from "../../images/edit.svg";
import PlusImg from "../../images/plus.svg";
import DeleteImg from "../../images/delete.svg";
import { useEffect, useState } from "react";
import { getUsers, deleteUsers } from "../../utils/services/assignee";
import { Loader } from "../../components/Loader";
import swal from "sweetalert";
import { AddAssignee } from "./AddAssignee";
import { Modal } from "antd";
import { EditAssignee } from "./EditAssignee";
// complexity
export const Assignee = () => {
  const [loading, setLoading] = useState(true);

  const [assigneeList, setAssigneeList] = useState([]);
  const [isAddAssigneeModalOpen, addAssigneeModalOpen] = useState(false);
  const [isEditAssigneeModalOpen, editAssigneeModalOpen] = useState(false);
  const [dataItem, setDataItem] = useState(null)

  const AddShowModal = () => {
    addAssigneeModalOpen(true)
  };
  const addhandleOk = () => {
    addAssigneeModalOpen(false);
  };
  const addhandleCancel = () => {
    addAssigneeModalOpen(false);
  };

  const updatedAddData = (values) => {
    // const assigneeList = assigneeList.push(values);
    setAssigneeList([values, ...assigneeList]);
  };

  // Edit

  const editShowModal = (data) => {
    setDataItem(data);
    editAssigneeModalOpen(true);
  };
  const edithandleOk = () => {
    editAssigneeModalOpen(false);
  };
  const edithandleCancel = () => {
    editAssigneeModalOpen(false);
  };

  const updatedEditData = (values) => {
    const assigneeListCopy = assigneeList.map((items) =>
      items.id === values.id ? { ...assigneeList, ...values } : items
    );
    setAssigneeList(assigneeListCopy);
  };

  const handleEdit = (record) => {
    var data = {
      id: record.id,
      name: record.name,
      password: record.password,
      mobile: record.mobile,
      email: record.email,
      role: record.role,
      status: record.status,
    };
    editShowModal(data);
  };
  useEffect(() => {
    getUsers(
      (res) => {
        setAssigneeList(res.data.data);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  }, []);

  // delete data
  const handleDeleteClick = (record) => {
    swal({
      title: record.name,
      text: "Are you sure you want to delete this assignee?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((willDelete) => {
      if (willDelete) {
        var data = {
          id: record.id,
        };
        deleteUsers(
          data,
          (success) => {
            const updatedList = assigneeList.filter(
              (item) => item.id !== record.id
            );
            setAssigneeList(updatedList);
          },
          (errorMessages) => { }
        );
        swal("Complexity has been deleted!", {
          icon: "success",
        });
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
  };

  const columns = [
    {
      title: "S.NO",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      render: (index, text, record) => (
        record + 1
      ),
      width: "4%"
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: "10%"
    },
    {
      title: "Email Id",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      width: "10%"
    },
    {
      title: "Mobile No",
      dataIndex: "mobile",
      key: "mobile",
      ellipsis: true,
      width: "10%"
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      ellipsis: true,
      width: "10%",
      render: (_, { status }) => (
        (status === 0 ? "Active" : "In Active")
      ),

    },

    {
      title: "Edit",
      key: "view",
      align: "center",
      render: (_, record) => (
        <Tooltip placement="top" title="Edit" onClick={() => handleEdit(record)}>
          <ActionItem style={{ margin: "0px 12px" }}>
            <img src={EditImg} style={{ width: "17px" }} alt="edit" />
          </ActionItem>
        </Tooltip>
      ),
      width: "4%"
    },
    {
      title: "Delete",
      key: "delete",
      align: "center",
      render: (_, record) => (
        <Tooltip
          placement="top"
          title="Delete"
          onClick={() => handleDeleteClick(record)}
        >
          <ActionItem style={{ margin: "0px 15px" }}>
            <img src={DeleteImg} style={{ width: "17px" }} alt="delete" />
          </ActionItem>
        </Tooltip>
      ),
      width: "4%"
    },
  ];

  return (
    <>
      <NavBar />

      <div>
        <br></br>
        <span
          style={{ float: "right", "margin-right": "6vh", cursor: "pointer" }}
          onClick={AddShowModal}
        >
          <img src={PlusImg} alt=""></img>
        </span>
      </div>
      <TableWrapper>
        {loading ? (
          <Loader />
        ) : (
          <Table
            columns={columns}
            dataSource={assigneeList}
            scroll={{ x: true, y: true }}
            pagination={{
              position: ["bottomRight"],
              pageSize: 10,
            }}
          />
        )}
      </TableWrapper>
      {/* Add */}
      <Modal
        open={isAddAssigneeModalOpen}
        title="Add Assignee"
        centered={true}
        footer={null}
        onOk={addhandleOk}
        onCancel={addhandleCancel}
        style={{
          top: 20,
        }}
        width={900}
        destroyOnClose={true}
        className="editModal"
      >
        <AddAssignee
          cancel={addhandleCancel}
          updatedData={updatedAddData}
        />
      </Modal>

      {/* Edit */}
      <Modal
        open={isEditAssigneeModalOpen}
        title="Edit Assignee"
        centered={true}
        footer={null}
        onOk={edithandleOk}
        onCancel={edithandleCancel}
        style={{
          top: 20,
        }}
        width={900}
        destroyOnClose={true}
        className="editModal"
      >
        <EditAssignee
          data={dataItem}
          cancel={edithandleCancel}
          updatedData={updatedEditData}
        />
      </Modal>
    </>
  );
};

const TableWrapper = styled.div`
  padding: 25px;
  .ant-table {
    border-radius: ;
  }
  .ant-table-thead .ant-table-cell {
    background: transparent;
    border-bottom: 1px solid ${palette.WHITE_SMOKE};
    text-transform: uppercase;
    font-family: ${palette.FONT_FAMILY};
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: ${palette.PRIMARY_COLOR};
    padding: 12px 7px !important;
    &:before {
      display: none;
    }
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    border-bottom: none;
    padding: 12px 7px !important;
    font-family: ${palette.FONT_FAMILY};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: ${palette.PRIMARY_COLOR};
  }
`;

const ActionItem = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const div = styled.div`
  p {
    width: 173px;
    height: 18px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: ${palette.PRIMARY_COLOR};
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 28px;
  }
`;

const TagStatus = styled.div`
  padding: 5px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 10px;
  text-transform: capitalize;
  text-align: center;
  background: ${({ status }) => (status === "0" ? "#E4FAEE" : "#FFEBEB")};
  color: ${({ status }) => (status === "0" ? "#489775" : "#AB1717")};
`;
