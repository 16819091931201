import { BASE_URL, BOM_BUILD_URL, ESTIMATE_LEVEL_URL } from "../helper";
import logger from "../logger";
import Interceptor from "./interceptor";

const request = new Interceptor();

// BOM Build
export const getAllBomBuild = async (success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.get(`${BASE_URL}/${BOM_BUILD_URL}`, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};

export const deleteBOM = async (data, success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.delete(`${BASE_URL}/${BOM_BUILD_URL}/${data.id}`, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};


export const updateBomBuild = async (data, success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.put(`${BASE_URL}/${BOM_BUILD_URL}`, data, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};


export const addBomBuild = async (data, success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.post(`${BASE_URL}/${BOM_BUILD_URL}`, data, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};

// Estimate Levels
export const getAllEstimateLevel = async (success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.get(`${BASE_URL}/${ESTIMATE_LEVEL_URL}`, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};

export const deleteEstimateLevel = async (data, success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.delete(`${BASE_URL}/${ESTIMATE_LEVEL_URL}/${data.id}`, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};

export const updateEstimateLevel = async (data, success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.put(`${BASE_URL}/${ESTIMATE_LEVEL_URL}`, data, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};

export const addEstimateLevelBuild = async (data, success, error) => {
  const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
  };
  try {
    const response = await request.post(`${BASE_URL}/${ESTIMATE_LEVEL_URL}`, data, config);
    success(response?.data);
  } catch (err) {
    error(err);
    logger(err);
  }
};