import { Button, Form, Input, InputNumber } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import * as palette from "../../styles/variables";
import { addEstimateLevelBuild } from "../../utils/services/multiplier";

export const AddEstimateLevel = ({
  cancel,
  updatedData,
}) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onAddEstimateLevel = (values) => {
    setLoading(true);
    addEstimateLevelBuild(
      values,
      (res) => {
        setLoading(false);
        updatedData(res.data);
        cancel();
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  return (
    <FormContainer>
      <Form
        form={form}
        name="add estimate level"
        colon={false}
        requiredMark={false}
        labelCol={{ span: 24 }}
        onFinish={onAddEstimateLevel}
        autoComplete="off"
      >
        <div className="formItems">
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter the estimate name",
                placeholder: "Basic usage",
              },
            ]}
          >
            <Input
              placeholder="Enter the estimate name"
              style={{
                width: "98%",
              }}
            />
          </Form.Item>

          <Form.Item
            label="Response (message)"
            name="response"
            rules={[
              {
                required: false,
                message: "Please enter the response message",
                placeholder: "Please enter the response message",
              },
            ]}
          >
            <Input
              placeholder="Please enter the response message"
              style={{
                width: "98%",
              }}
            />
          </Form.Item>
        </div>
        <div className="footerBtn">
          <Form.Item>
            <Button className="outlinedBtn" onClick={cancel}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              className="primaryBtn"
              htmlType="submit"
              style={{ padding: "0px 24px" }}
              disabled={loading}
            >
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  padding: 20px 0px;
  .formItems {
    display: grid;
    grid-template-columns: auto auto auto;
    @media only screen and (max-width: 769px) {
      grid-template-columns: auto auto;
    }
  }
  .footerBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 20px 30px 0px;
    gap: 20px;
    .ant-form-item {
      margin-bottom: 0px;
    }
  }
`;
